import styled from 'styled-components';

import Motion from '~components/atoms/Motion';

export const Paragraph = styled(Motion.p)`
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.2px;

    color: #111111;
`;
