// This component is included on every page, don't import usedapp and ethers here
import {useState} from 'react';
import Link from 'next/link';
import {useFirstMountState} from 'react-use';

import Logo from '~assets/icons/logo.svg';
// import Countdown from '~components/atoms/Countdown';
import {useScopeTranslation} from '~hooks/useScopeTranslation';
import Instagram from '~assets/icons/instagram.svg';
import Facebook from '~assets/icons/facebook.svg';
import Telegram from '~assets/icons/telegram.svg';
import Twitter from '~assets/icons/twitter.svg';
import Discord from '~assets/icons/discord.svg';
import Button from '~components/atoms/Button';
import ShootingStars from '~components/atoms/ShootingStars';
import RWDHelper from '~components/atoms/RWDHelper';
import GridBackground from '~components/atoms/GridBackground';
import {Routes} from '~config/routes';
import WalletButtonContent from '~components/atoms/WalletButtonContent';
// import {useCountdown} from '~components/providers/CountdownProvider';

import {screens, Screens} from '../Homepage';

import {
    CountdownContainer,
    Drawer,
    DrawerTopBar,
    Hamburger,
    LinkItem,
    MiddleContainer,
    SocialsContainer,
    TopBar,
    TopBarProps,
} from './styles';

const Socials = () => {
    const {t} = useScopeTranslation('common', 'menu.socials');

    return (
        <SocialsContainer>
            <a href={t('telegram')} target="_blank">
                <Telegram />
            </a>
            <a href={t('twitter')} target="_blank">
                <Twitter />
            </a>
            <a href={t('discord')} target="_blank">
                <Discord />
            </a>
            <a href={t('instagram')} target="_blank">
                <Instagram />
            </a>
            <a href={t('facebook')} target="_blank">
                <Facebook />
            </a>
        </SocialsContainer>
    );
};

type HamburgerMenuProps = TopBarProps & {
    screensToShow: Screens[];
    wallet?: string;
    handleWalletClick?: () => void;
};

const HamburgerMenu = ({
    screensToShow,
    wallet,
    handleWalletClick,
    ...topBarProps
}: HamburgerMenuProps) => {
    const {t} = useScopeTranslation('common', 'menu');

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isFirstRender = useFirstMountState();

    // const {date} = useCountdown();

    return (
        <>
            <TopBar {...topBarProps} isFirstRender={isFirstRender}>
                <Link
                    href={{
                        pathname: Routes.Homepage,
                        query: {
                            screen: 'about-us',
                        },
                    }}
                    passHref
                >
                    <a>
                        <Logo />
                    </a>
                </Link>
                <Link
                    href={{
                        pathname: Routes.Homepage,
                        query: {
                            screen: 'launch',
                        },
                    }}
                    passHref
                >
                    <CountdownContainer>
                        {/* <Countdown
                            label={t('common:countdown.label')}
                            date={date}
                            completed={null}
                        /> */}
                    </CountdownContainer>
                </Link>
                <Socials />
                <Button
                    {...(wallet
                        ? {onClick: handleWalletClick}
                        : {
                              href: Routes.Mint,

                              'data-test': true,
                          })}
                >
                    {wallet ? (
                        <WalletButtonContent walletAddress={wallet} />
                    ) : (
                        t('buttonText')
                    )}
                </Button>
                <button onClick={() => setIsDrawerOpen(true)}>
                    <Hamburger isOpen={isDrawerOpen} />
                </button>

                <RWDHelper mobile={<GridBackground />} />
            </TopBar>

            <Drawer
                initial={{
                    x: '100vw',
                }}
                animate={{
                    x: isDrawerOpen ? '0vw' : '100vw',
                }}
                transition={{
                    duration: 0.7,
                    ease: 'easeInOut',
                }}
                onClick={(event) =>
                    event.target &&
                    ['A', 'BUTTON'].includes(
                        (event.target as HTMLElement).tagName,
                    ) &&
                    setIsDrawerOpen(false)
                }
                mobile={true}
            >
                <ShootingStars delay={2} count={4} color="invert" />
                <DrawerTopBar>
                    <Button
                        {...(wallet
                            ? {onClick: handleWalletClick}
                            : {
                                  href: Routes.Mint,
                              })}
                        kind="white"
                    >
                        {wallet ? (
                            <WalletButtonContent walletAddress={wallet} />
                        ) : (
                            t('buttonText')
                        )}
                    </Button>
                    <button onClick={() => setIsDrawerOpen(false)}>
                        <Hamburger isOpen={isDrawerOpen} />
                    </button>
                </DrawerTopBar>
                <MiddleContainer>
                    {/* <Countdown label={t('common:countdown.label')} date={date} /> */}
                    {(
                        Object.entries(
                            t('common:screens', {returnObjects: true}),
                        ) as [Screens, string][]
                    )
                        .filter(([_, label]) => label)
                        .map(([screen, label]) => (
                            <Link
                                key={screen}
                                passHref
                                href={{
                                    query: {
                                        screen:
                                            screen === screens[0] ? null : [screen],
                                    },
                                }}
                            >
                                <LinkItem>{label}</LinkItem>
                            </Link>
                        ))}
                </MiddleContainer>
                <Socials />
            </Drawer>
        </>
    );
};

export default HamburgerMenu;
