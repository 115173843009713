import styled from 'styled-components';

import {above, below} from '~config/breakpoints';

export const GridBackgroundContainer = styled.div`
    position: absolute;
    inset: 0;
    pointer-events: none;
    --h-color: #f0f0f0;
    --background-color: #fbfbfb;
    --spacing: 324px;

    ${above.laptop} {
        background: linear-gradient(90deg, var(--h-color) 1px, transparent 1px),
            linear-gradient(var(--background-color) 1px, transparent 1px),
            linear-gradient(90deg, var(--background-color) 1px, transparent 1px);
        background-size: calc((100vw - (var(--h-padding) * 2)) / 4 - 0.5px)
                var(--spacing),
            calc((100vw - (var(--h-padding) * 2)) / 4 - 0.5px)
                calc(var(--spacing) / 2),
            calc((100vw - (var(--h-padding) * 2)) / 4 - 0.5px) var(--spacing);
        background-position-x: var(--h-padding), var(--h-padding),
            calc(var(--spacing) / 2 + var(--h-padding));
        background-clip: content-box;
        padding: 0 var(--h-padding);
        z-index: -1;
        pointer-events: none;

        transition: background-position-y ease-in-out 1s;
    }

    ${below.laptop} {
        z-index: -1;

        &::before,
        &::after {
            content: '';
            position: absolute;
            inset-block: 0;
            width: 1px;
            background: var(--h-color);
        }

        &::before {
            left: var(--h-padding);
        }

        &::after {
            right: var(--h-padding);
        }
    }
`;
