import styled from 'styled-components';

export const ScrollDetector = styled.div`
    position: absolute;
    z-index: -1;
    pointer-events: none;
    inset-inline: 0;
    top: calc(-1 * var(--topbar-height));
    height: 100vh;
    max-height: 100%;
    background: none;
`;
