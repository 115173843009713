import {useIsPresent} from 'framer-motion';
import {memo} from 'react';

import Astronaut from '~components/atoms/Astronaut';
import InfoWithImage, {InfoWithImageProps} from '~components/atoms/InfoWithImage';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import Orbits, {OrbitsProps} from '~components/atoms/Orbits';
import RWDHelper from '~components/atoms/RWDHelper';
import {useScopeTranslation} from '~hooks/useScopeTranslation';
import {orbitsConfig} from '~components/organisms/Homepage';

import {AstronautContainer, CreationProcessContainer} from './styles';

const BASE_DELAY = 1;

type CreationProcessProps = {};

const CreationProcess = ({}: CreationProcessProps) => {
    const {t} = useScopeTranslation('homepage', 'creationProcess');
    const isPresent = useIsPresent();

    return (
        <CreationProcessContainer>
            <MobileScreenScrollDetector screen="creation-process" />
            {(t('items', {returnObjects: true}) as InfoWithImageProps[]).map(
                ({title, ...props}, i) => (
                    <InfoWithImage
                        {...props}
                        title={
                            title && `${String(i + 1).padStart(2, '0')}. ${title}`
                        }
                        delay={isPresent ? BASE_DELAY : 0}
                        key={`${i}_${title}`}
                    />
                ),
            )}
            <RWDHelper
                mobile={
                    <AstronautContainer>
                        <Orbits
                            {...(orbitsConfig['creation-process'] as OrbitsProps)}
                        />

                        <Astronaut />
                    </AstronautContainer>
                }
            />
        </CreationProcessContainer>
    );
};

export default memo(CreationProcess);
