import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {below} from '~config/breakpoints';

export const OrbitsContainer = styled(Motion.div)`
    position: fixed;
    inset: 0;
    pointer-events: none;

    ${below.laptop} {
        position: absolute;
    }
`;

export const Orbit = styled(Motion.div)`
    position: absolute;
    --size: 735px;
    width: var(--size);
    height: var(--size);
    top: calc(50% - var(--size) / 2);
    left: calc(50% - var(--size) / 2);
    background: url('/assets/bg/orbit-dots.svg');
`;
