import {useInView} from 'framer-motion';
import {memo, useEffect, useRef} from 'react';

import {Screens} from '~components/organisms/Homepage';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';

import RWDHelper from '../RWDHelper';

import {ScrollDetector} from './styles';

type MobileScreenScrollDetectorProps = {
    screen: Screens;
};

const Inner = ({screen}: MobileScreenScrollDetectorProps) => {
    const ref = useRef(null);

    const isInView = useInView(ref, {
        amount: 0.75,
    });
    const {setScreen} = useScrollNavigation() || {};

    useEffect(() => {
        if (ref.current) {
            if (isInView) {
                setScreen?.(screen, 'inView');
            }
        }
    }, [isInView]);

    return <ScrollDetector ref={ref} id={screen} />;
};

const MobileScreenScrollDetector = ({screen}: MobileScreenScrollDetectorProps) => {
    return <RWDHelper mobile={<Inner screen={screen} />} />;
};

export default memo(MobileScreenScrollDetector);
