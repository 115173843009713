import {forwardRef, ReactNode} from 'react';

import {LayoutContainer} from './styles';

type LayoutProps = {
    children: ReactNode;
};

const Layout = forwardRef<HTMLDivElement, LayoutProps>(({children}, ref) => {
    return <LayoutContainer ref={ref}>{children}</LayoutContainer>;
});

export default Layout;
