import {AnimatePresence, useIsPresent} from 'framer-motion';
import Link from 'next/link';
import {useMemo, useState} from 'react';

import {useScopeTranslation} from '~hooks/useScopeTranslation';
import InstagramIcon from '~assets/icons/instagram.svg';
import TwitterIcon from '~assets/icons/twitter.svg';
import Motion from '~components/atoms/Motion';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';

import {
    Description,
    HashTag,
    HashTagsContainer,
    ImageContainer,
    Name,
    PersonContainer,
    Position,
    SocialsContainer,
    Spacer,
    TeamContainer,
    TeamSectionContainer,
    TextContainer,
    Title,
} from './styles';

type TeamMemberType = {
    name: string;
    imageUrl?: string;
    position?: string;
    instagram?: string;
    twitter?: string;
    tags: string[];
};

const BASE_DELAY = 1;

type TeamProps = {};

const Team = ({}: TeamProps) => {
    const {t} = useScopeTranslation('homepage', 'team');

    const isPresent = useIsPresent();

    const members = useMemo(
        () => t('members', {returnObjects: true}) as TeamMemberType[],
        [t],
    );

    const tags = useMemo(
        () => Array.from(new Set(members.flatMap((member) => member.tags))),
        [members],
    );

    const [selectedTags, setSelectedTag] = useState(() => tags[0]);

    return (
        <TeamSectionContainer>
            <MobileScreenScrollDetector screen="team" />
            <TextContainer>
                <Title
                    initial={{opacity: 0, y: '100%'}}
                    animate={{
                        opacity: 1,
                        y: '0%',
                    }}
                    exit={{opacity: 0, y: '-100%'}}
                    transition={{
                        delay: isPresent ? BASE_DELAY + 0.5 : 0,
                        ease: 'easeOut',
                        duration: 1,
                    }}
                >
                    {t('title')}
                </Title>
                <Description
                    initial={{opacity: 0, y: '100%'}}
                    animate={{
                        opacity: 1,
                        y: '0%',
                    }}
                    exit={{opacity: 0, y: '-100%'}}
                    transition={{
                        delay: isPresent ? BASE_DELAY + 1 : 0,
                        ease: 'easeOut',
                        duration: 1,
                    }}
                >
                    {t('description')}
                </Description>
            </TextContainer>
            <HashTagsContainer>
                {tags.map((hashtag, i) => (
                    <HashTag
                        key={hashtag}
                        onClick={() => setSelectedTag(hashtag)}
                        disabled
                        initial={{
                            color: '#000000',
                            background: 'rgba(17,17,17,0)',
                            opacity: 0,
                        }}
                        animate={{
                            color: hashtag === selectedTags ? '#ffffff' : '#000000',
                            background:
                                hashtag === selectedTags
                                    ? 'rgba(17,17,17,1)'
                                    : 'rgba(17,17,17,0)',
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        transition={{
                            ease: 'easeOut',
                            duration: 0.3,
                            opacity: {
                                delay: isPresent ? BASE_DELAY + 2 + i * 0.3 : 0,
                            },
                        }}
                        mobile={{
                            deepMerge: true,
                            initial: {
                                opacity: 1,
                            },
                        }}
                    >
                        {'#'}
                        {hashtag}
                    </HashTag>
                ))}
            </HashTagsContainer>
            <TeamContainer>
                <Spacer />
                {members.map(
                    (
                        {name, imageUrl, position, instagram, twitter /* tags */},
                        i,
                    ) => (
                        <PersonContainer
                            key={`${name}_${i}`}
                            initial={{opacity: 0, y: '50%'}}
                            animate={{
                                opacity: 1,
                                y: '0%',
                            }}
                            exit={{opacity: 0, y: '-100%'}}
                            transition={{
                                delay: isPresent ? BASE_DELAY : 0,
                                ease: 'easeOut',
                                duration: 0.5,
                            }}
                        >
                            <ImageContainer
                                mobile={{
                                    initial: {
                                        opacity: 0,
                                    },
                                    animate: false,
                                    whileInView: {
                                        opacity: 1,
                                    },
                                    transition: {
                                        ease: 'easeOut',
                                        duration: 1,
                                        delay: 0.5,
                                    },
                                    viewport: {
                                        once: true,
                                    },
                                }}
                            >
                                <AnimatePresence>
                                    {imageUrl /* && tags.includes(selectedTags) */ && (
                                        <Motion.img
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            transition={{
                                                ease: 'easeOut',
                                                duration: 0.5,
                                            }}
                                            src={imageUrl}
                                            alt={name}
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'top center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            mobile={true}
                                        />
                                    )}
                                </AnimatePresence>
                            </ImageContainer>
                            <Motion.div
                                initial={{opacity: 0, y: '10%'}}
                                animate={{
                                    opacity: 1,
                                    y: '0%',
                                }}
                                transition={{
                                    delay: BASE_DELAY + 0.5,
                                    ease: 'easeOut',
                                    duration: 0.5,
                                }}
                            >
                                <Name>{name}</Name>
                                <Position>{position}</Position>
                                <SocialsContainer>
                                    {instagram && (
                                        <Link href={instagram} passHref>
                                            <a>
                                                <InstagramIcon />
                                            </a>
                                        </Link>
                                    )}
                                    {twitter && (
                                        <Link href={twitter} passHref>
                                            <a>
                                                <TwitterIcon />
                                            </a>
                                        </Link>
                                    )}
                                </SocialsContainer>
                            </Motion.div>
                        </PersonContainer>
                    ),
                )}
            </TeamContainer>
        </TeamSectionContainer>
    );
};

export default Team;
