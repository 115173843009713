import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {above, below} from '~config/breakpoints';

export const BubblesContainer = styled.div`
    ${above.laptop} {
        position: fixed;
        left: calc((100vw - 1440px) / 2);
        bottom: 0;
        right: 0;
        height: 1000px;
        overflow: hidden;
    }
    ${below.laptop} {
        position: absolute;
        overflow: hidden;
        inset: 0;

        &::after {
            content: '';
            position: absolute;
            inset-inline: 0;
            bottom: 0;
            height: 100px;
            z-index: 10;
            background: linear-gradient(
                to top,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0.9) 20%,
                rgba(255, 255, 255, 0.5) 70%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }
`;

export const BubbleWrapper = styled(Motion.div)`
    position: absolute;
    --size: 200px;
    width: var(--size);
    height: var(--size);
    top: calc(-1 * var(--size));
    left: calc(-1 * var(--size));
    pointer-events: none;

    &:nth-child(2n + 1) {
        & > * {
            animation-direction: reverse;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Bubble = styled.div`
    @keyframes jiggle {
        0% {
            transform: translate(0, 0) rotate(0deg);
        }
        25% {
            transform: translate(1px, 1px) rotate(1deg);
        }
        50% {
            transform: translate(0, 0) rotate(0eg);
        }
        75% {
            transform: translate(-1px, 1px) rotate(-1deg);
        }
        100% {
            transform: translate(0, 0) rotate(0deg);
        }
    }

    position: absolute;
    --size: 109px;
    width: var(--size);
    height: var(--size);
    left: calc(50% - var(--size) / 2);
    top: calc(50% - var(--size) / 2);
    background: #111111;
    border-radius: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    pointer-events: all;

    color: #ffffff;

    animation: jiggle 400ms linear infinite;

    z-index: 1;
`;

export const Orbit = styled.div`
    position: absolute;
    --size: 155px;
    left: calc(50% - var(--size) / 2);
    top: calc(50% - var(--size) / 2);
    width: var(--size);
    height: var(--size);
    background: url('/assets/bg/orbit-dots-xs.svg');
    animation: spin 30s linear infinite;
    pointer-events: none;
`;

export const Planet = styled.div`
    position: absolute;
    --size: 117px;
    width: var(--size);
    height: var(--size);
    left: calc(50% - var(--size) / 2);
    top: calc(50% - var(--size) / 2);
    background: url('/assets/bg/planet.png');
    background-size: cover;
    z-index: 0;
    animation: spin 30s linear infinite;
    pointer-events: all;
    transition: -webkit-filter 1s linear;
    cursor: pointer;

    &:hover {
        filter: saturate(200%);
    }
`;
