import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';

export const SpecialElementsContainer = styled(Motion.section)`
    z-index: 1;

    ${above.laptop} {
        padding-left: 40px !important;
        position: relative;
    }
`;

export const Title = styled(Motion.h3)`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    ${above.laptop} {
        margin: 10vh 0 21px;
        max-width: 240px;
    }
`;

export const Text = styled(Paragraph)`
    ${above.laptop} {
        max-width: 240px;
    }

    ${below.laptop} {
        margin: 21px 0;
    }

    white-space: pre-line;
`;

export const ButtonsContainer = styled(Motion.div)`
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;

    ${above.laptop} {
        max-width: 324px;
        position: absolute;
        top: 35vh;
        left: 324px;
    }

    ${below.laptop} {
        margin: 50px 0;
    }
`;

export const SpecialElement = styled(Motion.button)`
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    color: #111111;
    padding: 18px 26px;
    white-space: nowrap;
    margin: 0 8px 8px 0;
    border: 1px dashed currentColor;
    transition: color ease-in-out 250ms, border-color ease-in-out 250ms;

    &:disabled {
        color: white;
        background: #111111;
        border-color: transparent;
    }
`;

export const InfosContainer = styled(Motion.div)`
    position: relative;
    width: 100%;
    height: 230px;

    ${above.laptop} {
        position: absolute;
        max-width: 275px;
        top: 40vh;
        left: calc(324px * 2);
    }
`;

export const InfoWrapper = styled(Motion.div)`
    padding-left: 65px;
    display: grid;
    gap: 24px;
    position: absolute;
    width: 100%;

    ${below.laptop} {
        padding-left: 40px;
    }
`;

export const Name = styled.h4`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: calc(100% + 14px);
        top: 2px;
        width: 10px;
        height: 10px;
        background: url('/assets/icons/dots.svg');
    }
`;

export const RarityType = styled.h6`
    font-weight: 500;
    font-size: 11px;
    line-height: 9px;
    letter-spacing: 0.2px;

    b {
        font-weight: 700;
        font-size: 12px;
        line-height: 9px;
    }
`;

export const Quote = styled.p`
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.2px;
`;

export const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
`;

export const Tag = styled.span`
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #6066ff;
    white-space: nowrap;
`;
