import styled, {css} from 'styled-components';

const animationEntrySetup = css`
    &:first-child {
        // Start mid way to hide first entry and and alternate with second car
        animation-delay: calc((var(--animationDuration) / 2) * -1);
    }

    &:last-child {
        animation-delay: 0;
    }
`;

export const Car = styled.div`
    @keyframes slideDown {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, 200%, 0);
        }
    }

    @keyframes slideUp {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -200%, 0);
        }
    }
    @keyframes slideLeft {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-200%, 0, 0);
        }
    }
    @keyframes slideRight {
        0% {
            transform: translate3d(0%, 0, 0);
        }
        100% {
            transform: translate3d(200%, 0, 0);
        }
    }

    position: absolute;
    will-change: transform;
    display: flex;
`;

type LaneProps = {
    direction: 'up' | 'down' | 'left' | 'right';
    axis: 'horizontal' | 'vertical';
    animationDuration: number;
    paused?: boolean;
    pauseOnHover?: boolean;
};

export const Lane = styled.div<LaneProps>`
    width: 100%;
    height: 100%;
    position: relative;
    --animationDuration: ${({animationDuration}) => `${animationDuration * 2}ms`};

    ${({direction}) => {
        switch (direction) {
            case 'up':
                return css`
                    ${Car} {
                        top: 100%;
                        flex-direction: column;
                        animation: slideUp var(--animationDuration) linear both
                            infinite;
                        ${animationEntrySetup};
                    }
                `;
            case 'down':
                return css`
                    ${Car} {
                        bottom: 100%;
                        flex-direction: column;
                        animation: slideDown var(--animationDuration) linear both
                            infinite;
                        ${animationEntrySetup};
                    }
                `;
            case 'left':
                return css`
                    ${Car} {
                        left: 100%;
                        animation: slideLeft var(--animationDuration) linear both
                            infinite;
                        ${animationEntrySetup};
                    }
                `;
            case 'right':
                return css`
                    ${Car} {
                        right: 100%;
                        animation: slideRight var(--animationDuration) linear both
                            infinite;
                        ${animationEntrySetup};
                    }
                `;
        }
    }}

    ${({pauseOnHover}) =>
        pauseOnHover &&
        css`
            &:hover {
                ${Car} {
                    animation-play-state: paused !important;
                }
            }
        `}

    ${({paused}) =>
        paused &&
        css`
            ${Car} {
                animation-play-state: paused !important;
            }
        `}
`;
