import {AnimatePresence} from 'framer-motion';
import {memo, useEffect, useState} from 'react';

import Motion from '~components/atoms/Motion';

import {Orbit, OrbitsContainer} from './styles';

// in seconds
const BASE_YEAR_DURATION = 60;

const PLANET_DIAMETER = 117;

export type OrbitsProps = {
    orbits: {
        scale: number;
        withPlanet?: boolean;
    }[];
};

const Orbits = ({orbits}: OrbitsProps) => {
    const [orbitDirection, setOrbitDirection] = useState<1 | -1>(1);

    useEffect(() => {
        setOrbitDirection(1);
    }, [orbits]);

    return (
        <OrbitsContainer
            initial={{
                opacity: 0,
                scale: 0.5,
            }}
            animate={{
                opacity: 1,
                scale: 1,
            }}
            exit={{
                opacity: 0,
                scale: 0.5,
            }}
            transition={{
                duration: 1,
                delay: 0,
            }}
        >
            <AnimatePresence>
                {orbits.map(({scale, withPlanet}, i) => (
                    <Orbit
                        key={`${i}`}
                        initial={{
                            opacity: 0,
                            scale: scale,
                            rotate: '0deg',
                        }}
                        animate={{
                            opacity: 1,
                            scale: scale,
                            rotate: `${orbitDirection * 360}deg`,
                        }}
                        transition={{
                            rotate: {
                                repeat: Infinity,
                                repeatType: 'loop',
                                ease: 'linear',
                                duration: BASE_YEAR_DURATION * scale,
                            },
                            default: {
                                ease: 'easeInOut',
                                duration: 0.5,
                            },
                        }}
                        mobile={true}
                    >
                        <AnimatePresence>
                            {withPlanet && (
                                <Motion.div
                                    style={{
                                        // Transform breaks dragSnapToOrigin
                                        position: 'absolute',
                                        left: `calc(50% - ${PLANET_DIAMETER}px / 2)`,
                                        top: `${(-1 * PLANET_DIAMETER) / 2}px`,
                                        cursor: 'pointer',
                                        pointerEvents: 'all',
                                        borderRadius: '100%',
                                        height: 'max-content',
                                    }}
                                    drag
                                    dragConstraints={{
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                    }}
                                    dragElastic={0.5}
                                    whileDrag={{
                                        scale: 1.2,
                                        filter: 'saturate(160%)',
                                    }}
                                    dragSnapToOrigin
                                    data-ignore-scroll
                                    onDragEnd={() =>
                                        // @ts-ignore
                                        setOrbitDirection((current) => current * -1)
                                    }
                                    mobile={true}
                                >
                                    <Motion.img
                                        src="/assets/bg/planet.png"
                                        width={PLANET_DIAMETER}
                                        height={PLANET_DIAMETER}
                                        initial={{
                                            rotate: '0deg',
                                            opacity: 0,
                                        }}
                                        animate={{
                                            rotate: `${
                                                -1 * orbitDirection * 360
                                            }deg`,
                                            opacity: 1,
                                        }}
                                        exit={{
                                            opacity: 0,
                                        }}
                                        transition={{
                                            rotate: {
                                                repeat: Infinity,
                                                repeatType: 'loop',
                                                ease: 'linear',
                                                duration:
                                                    BASE_YEAR_DURATION * scale * 0.3,
                                            },
                                            default: {
                                                ease: 'easeInOut',
                                                duration: 0.5,
                                            },
                                        }}
                                        mobile={true}
                                    />
                                </Motion.div>
                            )}
                        </AnimatePresence>
                    </Orbit>
                ))}
            </AnimatePresence>
        </OrbitsContainer>
    );
};

export default memo(Orbits);
