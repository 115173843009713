import styled from 'styled-components';

import Motion from '~components/atoms/Motion';

import {Paragraph} from '../Text/styles';

export const InfoWithImageContainer = styled(Motion.div)`
    max-width: 240px;
`;

export const Title = styled(Motion.h6)`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 18px 0 13px;
`;

export const Description = styled(Paragraph)`
    min-height: 50px;
`;
