import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {Copyright, FooterLinksContainer, Link} from './styles';

type FooterLinksProps = {
    absolute?: boolean;
};

const FooterLinks = ({absolute}: FooterLinksProps) => {
    const {t} = useScopeTranslation('common', 'footer');

    return (
        <FooterLinksContainer absolute={absolute}>
            <Copyright>{t('copyright')}</Copyright>
            {(
                t('links', {returnObjects: true}) as {
                    text: string;
                    url: string;
                }[]
            ).map(({text, url}) => (
                <Link href={url} target="_blank" key={text}>
                    {text}
                </Link>
            ))}
        </FooterLinksContainer>
    );
};

export default FooterLinks;
