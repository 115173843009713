import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {InfoWithImageContainer} from '~components/atoms/InfoWithImage/styles';
import {above, below} from '~config/breakpoints';
import {OrbitsContainer} from '~components/atoms/Orbits/styles';

export const CreationProcessContainer = styled(Motion.section)`
    display: flex;
    flex-direction: column;
    gap: 60px;

    ${above.laptop} {
        flex-wrap: wrap;
        align-content: space-between;
        padding-left: 40px !important;
        padding-top: 169px !important;
        padding-right: max(40px, var(--scrollnav-overlap)) !important;

        ${InfoWithImageContainer}:first-child {
            /* margin-top: max(calc(50vh - 460px), -50px); */
            margin-top: 0;
        }
    }

    ${below.laptop} {
        padding-right: 0px !important;
        margin: 0 0 40px;
    }

    @media (max-height: 700px) and (min-width: 1000px) {
        gap: 30px 60px;
        padding-top: 110px !important;
    }
`;

export const AstronautContainer = styled.div`
    margin: 0;
    height: 70vh;
    position: relative;
    z-index: -1;

    ${OrbitsContainer} {
        transform: translateX(43vw);
    }
`;
