import {useIsPresent} from 'framer-motion';
import {memo, useMemo} from 'react';
import {usePrevious} from 'react-use';

import Button from '~components/atoms/Button';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import RWDHelper from '~components/atoms/RWDHelper';
import InfiniteAnimatedSlider from '~components/molecules/InfiniteAnimatedSlider';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {
    ColumnContainer,
    GalleryContainer,
    MobileSliderContainer,
    RowContainer,
    SliderContainer,
    Text,
} from './styles';

const BASE_DELAY = 0;

type GalleryProps = {};

const Gallery = ({}: GalleryProps) => {
    const {t} = useScopeTranslation('homepage', 'gallery');
    const {screen} = useScrollNavigation();
    const previousScreen = usePrevious(screen);

    const isPresent = useIsPresent();

    const photos = useMemo(() => {
        return (t('photos', {returnObjects: true}) as string[]).map(
            (filename) => `${t('basePath')}${filename}`,
        );
    }, []);

    return (
        <GalleryContainer
            initial={
                screen === 'gallery'
                    ? {
                          y: '0',
                      }
                    : {
                          y: '-125vh',
                      }
            }
            animate={{
                y: screen === 'gallery' ? '0' : '-100vh',
            }}
            transition={{
                duration: 1,
                delay: isPresent
                    ? BASE_DELAY + 1
                    : previousScreen === 'superpowers'
                    ? 1
                    : 0,
            }}
        >
            <MobileScreenScrollDetector screen={'gallery'} />
            <Text
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: isPresent ? BASE_DELAY : 0,
                    ease: 'easeOut',
                    duration: 1,
                }}
            >
                {t('text')}
                {t('OpenSeaLink', {returnObject: true}) && (
                    <Button href={t('OpenSeaLink.url')}>
                        {t('OpenSeaLink.text')}
                    </Button>
                )}
            </Text>

            <RWDHelper
                desktop={
                    <>
                        {(['up', 'down'] as const).map((direction, i) => (
                            <SliderContainer
                                key={`${i}_${direction}`}
                                initial={{opacity: 0, y: '0%'}}
                                animate={{
                                    opacity: 1,
                                    y: '0%',
                                }}
                                exit={{opacity: 0, y: '0%'}}
                                transition={{
                                    delay:
                                        screen === 'gallery'
                                            ? BASE_DELAY + 1 + i * 0.5
                                            : 0,
                                    ease: 'easeInOut',
                                    duration: isPresent ? 1 : 0,
                                }}
                            >
                                <InfiniteAnimatedSlider
                                    direction={direction}
                                    pixelsPerSecond={25}
                                    key={i}
                                >
                                    <ColumnContainer>
                                        {photos
                                            .slice(
                                                direction === 'up'
                                                    ? 0
                                                    : Math.floor(photos.length / 2) +
                                                          1,
                                                direction === 'up'
                                                    ? Math.floor(photos.length / 2)
                                                    : photos.length,
                                            )
                                            .map((url, photoIndex) => (
                                                <img
                                                    src={url}
                                                    width="100%"
                                                    height="404"
                                                    key={`${url}_${photoIndex}`}
                                                />
                                            ))}
                                    </ColumnContainer>
                                </InfiniteAnimatedSlider>
                            </SliderContainer>
                        ))}
                    </>
                }
                mobile={
                    <MobileSliderContainer>
                        <InfiniteAnimatedSlider
                            direction="left"
                            pixelsPerSecond={25}
                        >
                            <RowContainer>
                                {photos.map((url, photoIndex) => (
                                    <img
                                        src={url}
                                        alt={`NFT ${photoIndex}`}
                                        width="353"
                                        height="404"
                                        key={`${url}_${photoIndex}`}
                                    />
                                ))}
                            </RowContainer>
                        </InfiniteAnimatedSlider>
                    </MobileSliderContainer>
                }
            />
        </GalleryContainer>
    );
};

export default memo(Gallery);
