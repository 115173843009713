import {useEffect, useRef} from 'react';
import platform from 'platform';

import {breakpoints} from '~config/breakpoints';

import {CursorContainer, Glow} from './styles';

const SPEED = 6;

type CursorGlowProps = {};

const CursorGlow = ({}: CursorGlowProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (window.innerWidth > breakpoints.laptop && ref.current) {
            const store: {
                rafHandle: number | null;
                mouseX: number;
                mouseY: number;
                x: number;
                y: number;
                previousTimestamp: number | null;
            } = {
                rafHandle: 0,
                mouseX: 0,
                mouseY: 0,
                x: 0,
                y: 0,
                previousTimestamp: null,
            };

            const handleMouseMove = (event: MouseEvent) => {
                store.mouseX = event.clientX;
                store.mouseY = event.clientY;
            };

            // eslint-disable-next-line no-undef
            const interpolate: FrameRequestCallback = (timestamp) => {
                if (store.previousTimestamp) {
                    const frameTime = timestamp - store.previousTimestamp;

                    const distX = store.mouseX - store.x;
                    const distY = store.mouseY - store.y;

                    const adjustedSpeed = SPEED / (1000 / frameTime);

                    store.x = store.x + distX * adjustedSpeed;
                    store.y = store.y + distY * adjustedSpeed;

                    if (ref.current) {
                        ref.current.style.transform = `translate3d(${store.x}px, ${store.y}px, 0)`;
                    }
                }

                store.previousTimestamp = timestamp;
                store.rafHandle = requestAnimationFrame(interpolate);
            };

            store.rafHandle = requestAnimationFrame(interpolate);

            const handleMouseEnter = (event: MouseEvent) => {
                if (ref.current) {
                    store.x = event.clientX;
                    store.y = event.clientY;
                    ref.current.style.opacity = '1';
                }
            };

            const handleMouseLeave = () => {
                if (ref.current && platform.os?.family !== 'OS X') {
                    ref.current.style.opacity = '0';
                }
            };

            document.addEventListener('mouseenter', handleMouseEnter);
            document.addEventListener('mouseleave', handleMouseLeave);
            document.addEventListener('mousemove', handleMouseMove, true);

            if (platform.os?.family === 'OS X') {
                ref.current.style.opacity = '1';
            }

            return () => {
                document.removeEventListener('mousemove', handleMouseMove, true);
                document.removeEventListener('mouseenter', handleMouseEnter);
                document.removeEventListener('mouseleave', handleMouseLeave);

                if (store.rafHandle !== null) {
                    window.cancelAnimationFrame(store.rafHandle);
                }
            };
        }
    }, []);

    return (
        <CursorContainer>
            <Glow ref={ref} />
        </CursorContainer>
    );
};

export default CursorGlow;
