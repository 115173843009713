import Banner from '~components/atoms/Banner';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import StatsGrid, {StatItemType} from '~components/atoms/StatsGrid';
import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {Container, Text} from './styles';

const BASE_DELAY = 0;

type StatsProps = {};

const Stats = ({}: StatsProps) => {
    const {t} = useScopeTranslation('homepage', 'stats');
    return (
        <Banner
            style={{bottom: 0}}
            initial={{
                y: '100%',
                opacity: 1,
            }}
            animate={{
                y: '0%',
                opacity: 1,
            }}
            exit={{
                y: '-100vh',
                opacity: 0,
            }}
            transition={{
                delay: BASE_DELAY,
                duration: 0.75,
                ease: 'easeOut',
            }}
            layoutId="stats-banner"
            mobile={{
                initial: false,
                animate: false,
                exit: undefined,
                variants: undefined,
                transition: undefined,
                layoutId: undefined,
            }}
        >
            <MobileScreenScrollDetector screen="stats" />
            <Container>
                <Text>{t('text')}</Text>
                <StatsGrid
                    items={t('stats', {returnObjects: true}) as StatItemType[]}
                />
            </Container>
        </Banner>
    );
};

export default Stats;
