import Image from 'next/image';
import {useEffect, useRef, useState} from 'react';

import {
    Description,
    PlayButton,
    Row,
    ThumbnailContainer,
    VideoContainer,
} from './styles';

type VideoProps = {
    url: string;
    posterUrl: string;
    description: string;
    descriptionDelay?: number;
};

const Video = ({url, posterUrl, description, descriptionDelay = 2}: VideoProps) => {
    const ref = useRef<HTMLVideoElement>(null);

    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        const handleChange = () => {
            if (document.fullscreenElement === ref.current) {
                setIsFullscreen(true);
            } else {
                setIsFullscreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleChange);
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            if (isFullscreen) {
                ref.current.play().catch(() => {});
            } else {
                ref.current.pause();
            }
        }
    }, [isFullscreen]);

    return (
        <VideoContainer isFullscreen={isFullscreen}>
            <Row onClick={() => ref.current?.requestFullscreen()}>
                <PlayButton />
                <ThumbnailContainer>
                    <Image src={posterUrl} layout="fill" objectFit="cover" />
                </ThumbnailContainer>
            </Row>
            {description && (
                <Description
                    initial={{opacity: 0, y: '100%'}}
                    animate={{opacity: 1, y: '0%'}}
                    transition={{
                        delay: descriptionDelay,
                        ease: 'easeOut',
                    }}
                >
                    {description}
                </Description>
            )}
            <video
                src={url}
                poster={posterUrl}
                autoPlay={false}
                loop
                preload="metadata"
                ref={ref}
            ></video>
        </VideoContainer>
    );
};

export default Video;
