import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {OrbitsContainer} from '~components/atoms/Orbits/styles';
import {below} from '~config/breakpoints';

export const OurMissionContainer = styled(Motion.section)`
    display: flex;

    ${below.laptop} {
        flex-direction: column;
    }
`;

export const AstronautContainer = styled.div`
    position: relative;
    height: 66vh;
    width: 100%;
    margin: 10px 0px 0px;
    z-index: -1;

    ${OrbitsContainer} {
        transform: translateX(55vw);
    }
`;
