import styled from 'styled-components';

import {BannerContainer} from '~components/atoms/Banner/styles';
import {above, below} from '~config/breakpoints';

export const LayoutContainer = styled.main`
    --h-padding: 30px;
    --topbar-height: 83px;
    --scrollnav-width: 0px;
    --scrollnav-overlap: 0px;
    --scrollnav-position: 4.7vw;
    --right-padding-with-scrollnav: var(--h-padding);

    ${above.laptop} {
        --h-padding: 71px;
        --topbar-height: 100px;
        --scrollnav-width: 160px;
        --scrollnav-overlap: calc(
            var(--scrollnav-width) - var(--h-padding) + var(--scrollnav-position)
        );
        --right-padding-with-scrollnav: calc(
            var(--h-padding) + var(--scrollnav-overlap)
        );
    }

    ${above.desktop} {
        --h-padding: calc((100vw - 1440px) / 2 + 71px);
    }

    ${below.laptop} {
        position: relative;
        overflow: hidden;
    }

    & > section {
        ${above.laptop} {
            position: absolute;
            top: 0;
            inset-inline: var(--h-padding);
            padding: 100px 0 75px;
            bottom: 0;
        }

        ${below.laptop} {
            padding: 8px var(--h-padding);
            position: relative;
            max-width: 100vw;

            & > ${BannerContainer} {
                left: calc(-1 * var(--h-padding));
            }
        }
    }
`;
