import {ComponentProps} from 'react';

import {useScopeTranslation} from '~hooks/useScopeTranslation';
import ArrowsIcon from '~assets/icons/arrows.svg';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import ShootingStars from '~components/atoms/ShootingStars';
import SpaceBackground from '~components/atoms/SpaceBackground';
import RWDHelper from '~components/atoms/RWDHelper';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import {ASTRONAUT_HEVC_URL, ASTRONAUT_VP9_URL} from '~components/atoms/Astronaut';

import {
    PreloaderContainer,
    PreloaderText,
    ScrollDownContainer,
    ScrollDownText,
} from './styles';

type PreloaderProps = {};

const spaceBackgroundOptions: ComponentProps<typeof SpaceBackground>['options'] = {
    particles: {
        number: {
            value: 430,
            limit: 1000,
            density: {
                enable: true,
                area: 1000,
            },
        },
        size: {
            value: 1.3,
        },
    },
};

// eslint-disable-next-line no-empty-pattern
const Preloader = ({}: PreloaderProps) => {
    const {t} = useScopeTranslation('homepage', 'preloader');

    const {next: nextScreen} = useScrollNavigation();

    return (
        <PreloaderContainer
            initial={{y: 0, opacity: 0}}
            animate={{opacity: 1}}
            exit={{y: '-100vh'}}
            transition={{duration: 1}}
        >
            <MobileScreenScrollDetector screen="preloader" />
            <RWDHelper
                mobile={
                    <SpaceBackground
                        options={spaceBackgroundOptions}
                        id="preloader-space-background"
                    />
                }
            />
            <RWDHelper
                mobile={<ShootingStars delay={1} color="white" duration={5} />}
                desktop={<ShootingStars delay={1} color="white" />}
            />
            <RWDHelper
                desktop={
                    <video
                        muted
                        playsInline
                        preload="auto"
                        style={{display: 'none'}}
                    >
                        <source
                            src={ASTRONAUT_HEVC_URL}
                            type='video/mp4; codecs="hvc1"'
                        />
                        <source src={ASTRONAUT_VP9_URL} type="video/webm" />
                    </video>
                }
            />
            <PreloaderText
                mobile={{
                    initial: {
                        opacity: 0,
                    },
                    animate: {
                        opacity: 1,
                    },
                    transition: {
                        duration: 2,
                        ease: 'easeOut',
                        delay: 0.5,
                    },
                }}
            >
                {t('intro')}
            </PreloaderText>
            <ScrollDownContainer onClick={nextScreen}>
                <ScrollDownText>{t('scrollDown')}</ScrollDownText>
                <ArrowsIcon />
            </ScrollDownContainer>
        </PreloaderContainer>
    );
};

export default Preloader;
