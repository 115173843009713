import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {above, below} from '~config/breakpoints';

export const FooterContainer = styled(Motion.div)`
    background-color: black;
    background: url('assets/bg/bw-space-background.png');
    color: white;
    z-index: 7;
    padding: 88px calc(var(--h-padding) + 175px) 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${above.laptop} {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }

    ${below.laptop} {
        padding: 175px 30px 18px;
        position: relative;
    }
`;

export const Address = styled.p`
    margin: 64px 0 50px;
    max-width: 200px;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-line;
`;

export const HandContainer = styled.div`
    position: relative;
    width: 165px;
    height: 143px;
    transform-style: preserve-3d;
    perspective: 300px;
`;

export const Hand = styled.img.attrs({
    src: '/assets/hand.png',
})`
    /* @keyframes hand {
        0% {
            transform: rotateX(3deg) rotateY(-10deg);
        }

        25% {
        }

        50% {
            transform: rotateX(-9deg) rotateY(10deg);
        }

        75% {
        }

        100% {
            transform: rotateX(3deg) rotateY(-10deg);
        }
    } */
    @keyframes hand {
        0% {
            transform: rotateX(6deg) rotateY(-13deg) rotateZ(0deg);
        }

        25% {
        }

        50% {
            transform: rotateX(-9deg) rotateY(10deg) rotateZ(360deg);
        }

        75% {
        }

        100% {
            transform: rotateX(6deg) rotateY(-13deg) rotateZ(720deg);
        }
    }
    width: 100%;
    height: 100%;
    animation: hand 12s infinite cubic-bezier(0.52, 0.3, 0.48, 0.72);
    border-radius: 100% !important;
`;

export const Ring = styled.div`
    @keyframes ring {
        0% {
            transform: rotateX(320deg) rotateY(0deg);
        }

        25% {
        }

        50% {
            transform: rotateX(250deg) rotateY(360deg);
        }

        75% {
        }

        100% {
            transform: rotateX(320deg) rotateY(720deg);
        }
    }

    width: 100%;
    padding-top: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: ring 12s infinite cubic-bezier(0.52, 0.3, 0.48, 0.72);

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 2px solid rgba(203, 208, 255, 1);
        border-radius: 100%;
        filter: blur(4px);
    }

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 2px solid #6066ff;
        border-radius: 100%;
    }
`;

export const ScrollTop = styled.button`
    display: grid;
    gap: 16px;
    margin: 50px 0 40px;
    color: inherit;
    justify-items: center;

    svg {
        transform: scale(-1, -1);
    }

    ${below.laptop} {
        margin: 170px 0 65px;
    }
`;
