import styled from 'styled-components';

import Motion from '~components/atoms/Motion';

export const CharacterContainer = styled(Motion.section)`
    position: relative;
`;

export const AstronautContainer = styled.div`
    height: 65vh;
    position: relative;
`;
