import styled, {css} from 'styled-components';

import Motion from '~components/atoms/Motion';
import {StyledButton} from '~components/atoms/Button/styles';
import {above, below} from '~config/breakpoints';
import {GridBackgroundContainer} from '~components/atoms/GridBackground/styles';

export const CountdownContainer = styled.a`
    margin: 0 auto;
    font-size: 12px;
    display: block;
`;

export const SocialsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        margin: 0 22px;

        ${above.laptop} {
            margin: 0 22px;
            transform: scale(0.8);
        }
    }
`;

export const Drawer = styled(Motion.div)`
    position: fixed;
    inset: 0;
    height: 100vh;
    max-width: 100vw;
    background: black;
    padding: 24px 30px 50px;
    color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: 9;

    ${above.laptop} {
        display: none;
    }

    ${SocialsContainer} {
        margin-top: auto;
    }
`;

export const DrawerTopBar = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const MiddleContainer = styled.div`
    display: grid;
    gap: 20px;
    margin: 60px 0 40px 96px;
`;

export const LinkItem = styled.a`
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: 0.2px;
    display: block;

    &:first-of-type {
        margin-top: 35px;
    }
`;

export type TopBarProps = {
    hideOnDesktop: boolean;
    color?: string;
    background?: string;
    isFirstRender?: boolean;
};

export const TopBar = styled(Motion.nav)<TopBarProps>`
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    position: fixed;
    top: 0;
    inset-inline: 0;
    max-width: 100vw;
    transform: translateY(-100%);
    color: ${({color}) => color || 'black'};
    background: ${({background}) => background || 'transparent'};
    padding: 34px var(--h-padding);
    max-height: var(--topbar-height);

    ${({isFirstRender}) =>
        !isFirstRender &&
        css`
            transition: transform ease-in-out 1000ms, color ease-in-out 500ms,
                background ease-in-out 500ms;
        `}

    ${above.laptop} {
        transform: translateY(0%);

        ${({hideOnDesktop}) =>
            hideOnDesktop &&
            css`
                transform: translateY(-100%);
            `}

        padding: 29px calc(var(--h-padding) + 8px);

        // hamburger button
        & > button:last-of-type {
            display: none;
        }
    }

    ${below.laptop} {
        transform: translateY(0%);

        & > ${StyledButton}, & > ${SocialsContainer}, & > ${CountdownContainer} {
            display: none;
        }

        ${GridBackgroundContainer} {
            transition: opacity ease-in-out 500ms;
        }

        ${({background}) =>
            background !== 'white' &&
            css`
                ${GridBackgroundContainer} {
                    transitino-duration: 300ms;
                    opacity: 0;
                }
            `}
    }
`;

export const Hamburger = styled.div<{isOpen: boolean}>`
    width: 35px;
    height: 20px;
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        --height: 3px;
        top: calc(50% - var(--height) / 2);
        inset-inline: 0;
        height: var(--height);
        background: currentColor;
        transition: transform ease-in-out 200ms;

        ${({isOpen}) =>
            isOpen
                ? css`
                      transform: rotate(calc(var(--direction) * 20deg));
                  `
                : css`
                      transform: translateY(
                          calc(var(--direction) * (var(--height) / 2 + 2px))
                      );
                  `}
    }

    &::before {
        --direction: -1;
    }

    &::after {
        --direction: 1;
    }
`;
