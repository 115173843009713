import {ComponentProps} from 'react';

import Arrows from '~assets/icons/arrows.svg';
import {useScopeTranslation} from '~hooks/useScopeTranslation';
import Logo from '~assets/icons/logo.svg';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import FooterLinks from '~components/molecules/FooterLinks';

import {
    Address,
    FooterContainer,
    Hand,
    HandContainer,
    Ring,
    ScrollTop,
} from './styles';

type FooterProps = ComponentProps<typeof FooterContainer> & {};

const Footer = ({...props}: FooterProps) => {
    const {t} = useScopeTranslation('common', 'footer');

    const {setScreen} = useScrollNavigation();

    return (
        <FooterContainer
            {...props}
            initial={{
                y: '100%',
            }}
            animate={{
                y: '0%',
            }}
            exit={{
                y: '100%',
            }}
            transition={{
                duration: 1,
                ease: 'easeOut',
            }}
        >
            <MobileScreenScrollDetector screen="footer" />
            <Logo />
            <Address
                dangerouslySetInnerHTML={{
                    __html: t('address'),
                }}
            />
            <HandContainer>
                <Hand />
                <Ring />
            </HandContainer>
            <ScrollTop onClick={() => setScreen(1)}>
                <Arrows />
                <span>{t('scrollTop')}</span>
            </ScrollTop>
            <FooterLinks />
        </FooterContainer>
    );
};

export default Footer;
