import {Transition} from 'framer-motion';
import {memo} from 'react';

import SpaceBackground from '~components/atoms/SpaceBackground';
import Motion from '~components/atoms/Motion';

import {PortalClipWrapper, Shadow} from './styles';

type PortalProps = {
    fullscreen: boolean;
};

const parralaxScale = 0.9;
const width = 18 * (1 / parralaxScale);
const height = 60 * (1 / parralaxScale);
const yOffset = 1;

const transition: Transition = {
    duration: 1,
    ease: 'easeInOut',
};

const Portal = ({fullscreen}: PortalProps) => {
    return (
        <>
            <PortalClipWrapper
                initial={
                    fullscreen
                        ? false
                        : {
                              clipPath: `polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)`,
                          }
                }
                animate={
                    fullscreen
                        ? {
                              clipPath: `polygon(100% 0%,0% 0%,0% 100%,100% 100%)`,
                          }
                        : {
                              clipPath: `polygon(${(100 - width) / 2 + width}% ${
                                  (100 - height) / 2 + yOffset
                              }%,${(100 - width) / 2}% ${
                                  (100 - height) / 2 + yOffset
                              }%,${(100 - width) / 2}% ${
                                  (100 - height) / 2 + height + yOffset
                              }%,${(100 - width) / 2 + width}% ${
                                  (100 - height) / 2 + height + yOffset
                              }%)`,
                          }
                }
                exit={{
                    clipPath: `polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)`,
                }}
                transition={transition}
            >
                <Motion.div
                    initial={false}
                    animate={
                        fullscreen
                            ? {
                                  scale: 1,
                                  y: 0,
                              }
                            : {
                                  scale: parralaxScale,
                                  y: `${-1 * (height / 10)}%`,
                              }
                    }
                    transition={transition}
                    style={{
                        display: 'flex',
                        transformOrigin: 'center center',
                        height: '100%',
                    }}
                >
                    <SpaceBackground id="portal-space-background" />
                </Motion.div>
            </PortalClipWrapper>
            <Motion.div
                initial={false}
                animate={
                    fullscreen
                        ? {x: 0, scaleY: 1}
                        : {
                              x: `${-((100 - width) / 2)}%`,
                              scaleY: height / 100,
                          }
                }
                transition={transition}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: '0',
                    top: '0',
                    zIndex: -1,
                    transformOrigin: 'center center',
                }}
            >
                <Shadow />
            </Motion.div>
        </>
    );
};

export default memo(Portal);
