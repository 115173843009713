import styled, {css} from 'styled-components';

import Motion from '~components/atoms/Motion';

export type StyledButtonProps = {
    kind?: 'white' | 'black';
};

export const StyledButton = styled(Motion.button).attrs({
    className: 'ignore-visited-link',
})<StyledButtonProps>`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding: 16px 30px 11px;
    min-width: 16px;
    position: relative;

    &:disabled,
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    ${({kind}) => {
        switch (kind) {
            case 'white': {
                return css`
                    background: white;
                    color: #111111;
                    outline: 1px solid currentColor;
                `;
            }
            default: {
                return css`
                    background-color: black;
                    background: url('/assets/bg/bw-space-background.png');
                    background-size: cover;
                    color: #ffffff;
                `;
            }
        }
    }}
`;
