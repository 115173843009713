import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {below} from '~config/breakpoints';

export const ScrollNavContainer = styled(Motion.div)`
    position: fixed;
    right: var(--scrollnav-position);
    bottom: 50px;
    display: grid;
    justify-items: flex-end;
    z-index: 11;
    mix-blend-mode: difference;
    color: #fff;

    ${below.laptop} {
        display: none;
    }
`;

export const NavItem = styled(Motion.a)`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-auto-columns: max-content 15px;
    gap: 53px;
    transition: filter ease-in-out 250ms;

    &:hover {
        filter: contrast(0.5) brightness(0.5);
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const Index = styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
`;

export const Label = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: right;
    align-self: flex-end;
`;

export const Separator = styled(Motion.div)`
    padding-left: 7.5px;
    align-self: flex-end;
    background: linear-gradient(to right, currentColor 1px, transparent 1px);
    background-repeat: no-repeat;
    background-position-y: 1px;
    background-size: 1px calc(100% - 40px);
`;
