import styled from 'styled-components';

import Motion from '~components/atoms/Motion';

export const PortalClipWrapper = styled(Motion.div)`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const Shadow = styled(Motion.div)`
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 100vw;
    background: #e1e1e7;
    opacity: 0.18;
    transform: skewY(32deg);
    transform-origin: 0 0;
`;
