import {useIsPresent} from 'framer-motion';

import Motion from '~components/atoms/Motion';
import Astronaut from '~components/atoms/Astronaut';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';

import {Screens} from '..';

import {AstronautContainer} from './styles';

export const astronautConfig: Partial<
    Record<Screens, {rotation?: number; speed?: number}>
> = {
    'about-us': {
        rotation: 150,
        speed: 0.8,
    },
    'our-mission-quote': {
        rotation: 0,
    },
    character: {
        rotation: 330,
    },
    'creation-process': {
        speed: 0.75,
    },
    launch: {
        rotation: 140,
    },
};

type HomepageAstronautProps = {};

const HomepageAstronaut = ({}: HomepageAstronautProps) => {
    const {screen} = useScrollNavigation();

    const isPresent = useIsPresent();

    return (
        <AstronautContainer>
            <Motion.div
                initial={{
                    scale: 0.65,
                    y: 200,
                    x: 50,
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    ...((['about-us', 'launch'] as Screens[]).includes(screen)
                        ? {
                              scale: 0.65,
                              y: 'calc(25vh - 200px)',
                              x: 0,
                          }
                        : {
                              scale: 1,
                              y: 0,
                              x: 0,
                          }),
                }}
                style={{
                    transformOrigin: 'bottom right',
                    position: 'relative',
                    height: '840px',
                    maxHeight: '90vh',
                }}
                transition={{
                    ease: 'easeInOut',
                    duration: 0.75,
                    delay: isPresent ? 0.1 : 0,
                }}
                exit={{
                    y: 400,
                    x: 50,
                    scale: 0.65,
                    opacity: 0,
                }}
            >
                <Astronaut speed={1} {...astronautConfig[screen]} />
            </Motion.div>
        </AstronautContainer>
    );
};

export default HomepageAstronaut;
