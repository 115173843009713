import Astronaut from '~components/atoms/Astronaut';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import RWDHelper from '~components/atoms/RWDHelper';
import ShootingStars from '~components/atoms/ShootingStars';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';

import {AstronautContainer, CharacterContainer} from './styles';

const BASE_DELAY = 0.5;

type CharacterProps = {};

const Character = ({}: CharacterProps) => {
    const {direction} = useScrollNavigation();

    return (
        <CharacterContainer
            initial={
                direction === 'up'
                    ? {
                          y: -500,
                          opacity: 0,
                      }
                    : undefined
            }
            animate={
                direction === 'up'
                    ? {
                          y: 0,
                          opacity: 1,
                      }
                    : undefined
            }
            transition={
                direction === 'up'
                    ? {
                          duration: 1,
                          delay: BASE_DELAY,
                      }
                    : undefined
            }
        >
            <MobileScreenScrollDetector screen="character" />
            <RWDHelper
                mobile={
                    <AstronautContainer>
                        <Astronaut rotation={330} speed={0.75} />
                    </AstronautContainer>
                }
            />

            <ShootingStars
                delay={BASE_DELAY}
                color="invert"
                key="shooting-stars-character"
            />
        </CharacterContainer>
    );
};

export default Character;
