import styled from 'styled-components';

import {below} from '~config/breakpoints';

export const CursorContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 10;

    ${below.laptop} {
        display: none;
    }
`;

export const Glow = styled.div`
    --width: 391px;
    --height: 232px;
    position: absolute;
    width: var(--width);
    height: var(--height);
    top: calc(-1 * var(--height) / 2);
    left: calc(-1 * var(--width) / 2);
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transform-origin: top left;
    pointer-events: none;
    transition: opacity ease-in-out 300ms;

    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 365px;
        height: 157px;
        transform: translate(-50%, -50%) rotate(-12.5deg);
        background: rgba(96, 102, 255, 0.15);
        filter: blur(80px);
    }

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 230px;
        height: 100px;
        background: rgba(96, 102, 255, 0.77);
        transform: translate(-50%, -50%) rotate(-12.5deg);
        filter: blur(70px);
    }
`;
