import {BubblesConfig} from '~components/molecules/Bubbles';

export const BUBBLES_CONFIG: BubblesConfig = [
    {
        x: 518,
        y: 230,
    },
    {
        x: 671,
        y: 362,
    },
    {
        x: 402,
        y: 403,
    },
    {
        x: 881,
        y: 469,
        withOrbit: true,
    },
    {
        x: 114,
        y: 506,
    },
    {
        x: 721,
        y: 570,
    },
    {
        x: 492,
        y: 582,
    },
    {
        x: 1043,
        y: 582,
    },
    {
        x: 560,
        y: 653,
        type: 'planet',
    },
    {
        x: 1098,
        y: 674,
    },
    {
        x: 352,
        y: 686,
        withOrbit: true,
    },
    {
        x: 881,
        y: 731,
    },
    {
        x: 156,
        y: 791,
    },
    {
        x: 498,
        y: 791,
    },
    {
        x: 1021,
        y: 804,
        withOrbit: true,
    },
    {
        x: 409,
        y: 849,
    },
    {
        x: 925,
        y: 843,
        type: 'planet',
    },
    {
        x: 80,
        y: 860,
    },
    {
        x: 701,
        y: 863,
    },
];

export const MOBILE_BUBBLES_CONFIG: BubblesConfig = [
    {
        x: 85,
        y: 70,
        withOrbit: true,
    },
    {
        x: -75,
        y: 170,
    },
    {
        x: 240,
        y: 180,
    },
    {
        x: 300,
        y: 270,
    },
    {
        x: 80,
        y: 300,
    },
    {
        x: 220,
        y: 420,
        withOrbit: true,
    },
    {
        x: -90,
        y: 430,
    },
    {
        x: 125,
        y: 465,
        type: 'planet',
    },
    {
        x: 30,
        y: 515,
    },
    {
        x: 270,
        y: 680,
        withOrbit: true,
    },
    {
        x: 70,
        y: 720,
    },
];
