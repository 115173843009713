import {useInView} from 'framer-motion';
import {memo, useState} from 'react';
import {useCallbackRef} from 'use-callback-ref';

import {useScrollNavigation} from '~components/providers/ScrollNavigation';

import {ShootingStarsContainer, Star, StarProps} from './styles';

type ShootingStarsProps = StarProps & {
    delay: number;
    duration?: number;
    count?: number;
};

const ShootingStars = ({
    delay,
    color,
    duration = 4,
    count = 8,
}: ShootingStarsProps) => {
    const [ref, setRef] = useState({current: null});
    const callbackRef = useCallbackRef(null, (current) => setRef({current}));
    const isInView = useInView(ref, {
        once: true,
        amount: 0.01,
    });
    const {direction} = useScrollNavigation() || {};

    if (direction === 'up') {
        return null;
    }

    return (
        <ShootingStarsContainer ref={callbackRef}>
            {Array.from(Array(count)).map((_, i) => (
                <Star
                    color={color}
                    key={i}
                    initial={{
                        translateX: '-100vw',
                        translateY: '-100vh',
                    }}
                    animate={{
                        translateX: '120vw',
                        translateY: '100vh',
                    }}
                    style={{
                        rotateZ: '30deg',
                    }}
                    transition={{
                        duration: duration + i * Math.random() * 0.5,
                        delay: delay,
                        ease: 'linear',
                    }}
                    mobile={{
                        initial: {
                            translateX: '-150vw',
                            translateY: '-60vh',
                        },
                        animate: isInView && {
                            translateX: '150vw',
                            translateY: '60vh',
                        },
                    }}
                />
            ))}
        </ShootingStarsContainer>
    );
};

export default memo(ShootingStars);
