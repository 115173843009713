import styled, {css} from 'styled-components';

import Motion from '~components/atoms/Motion';
import {above, below} from '~config/breakpoints';

export const ShootingStarsContainer = styled(Motion.div)`
    position: fixed;
    overflow: hidden;
    pointer-events: none;

    ${above.laptop} {
        inset: 0;
    }

    ${below.laptop} {
        inset: 0;
        position: absolute;
    }
`;

export type StarProps = {color: 'invert' | 'white'};

export const Star = styled(Motion.div)<StarProps>`
    position: absolute;
    height: 1px;

    ${({color}) => {
        switch (color) {
            case 'invert': {
                return css`
                    background: linear-gradient(to left, transparent 0%, white 100%);
                    backdrop-filter: invert(100%);
                `;
            }
            case 'white': {
                return css`
                    background: linear-gradient(
                        to right,
                        transparent 0%,
                        hsla(43, 30%, 79%, 1) 100%
                    );
                `;
            }
        }
    }}

    &:nth-child(1) {
        top: 30vh;
        left: 25vw;
        width: 240px;
    }
    &:nth-child(2) {
        top: 28vh;
        left: 65vw;
        width: 200px;
    }
    &:nth-child(3) {
        top: 50vh;
        left: 43vw;
        width: 220px;
    }
    &:nth-child(4) {
        top: 70vh;
        left: 35vw;
        width: 170px;
    }
    &:nth-child(5) {
        top: 75vh;
        left: 20vw;
        width: 150px;
    }
    &:nth-child(6) {
        top: 47vh;
        left: 70vw;
        width: 200px;
    }
    &:nth-child(7) {
        top: 80vh;
        left: 70vw;
        width: 250px;
    }
    &:nth-child(8) {
        top: 40vh;
        left: 16vw;
        width: 320px;
    }
`;
