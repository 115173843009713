import LinkIcon from '~assets/icons/link.svg';

import {Address, Avatar, WalletButtonContentContainer} from './styles';

type WalletButtonContentProps = {
    walletAddress: string;
};

const WalletButtonContent = ({walletAddress}: WalletButtonContentProps) => {
    return (
        <WalletButtonContentContainer>
            <Avatar />
            <Address>
                {walletAddress.slice(0, 9)}
                {'...'}
                {walletAddress.slice(-4)}
            </Address>
            <LinkIcon />
        </WalletButtonContentContainer>
    );
};

export default WalletButtonContent;
