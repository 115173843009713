import {useCallback, useEffect, useRef} from 'react';
// @ts-ignore
import ease from 'easy-ease';
import {useInView} from 'framer-motion';

import {AstronautVideo} from './styles';

export const ASTRONAUT_HEVC_URL = `${process.env.NEXT_PUBLIC_ASSETS_URL}/hevc-alpha.mov`;
export const ASTRONAUT_VP9_URL = `${process.env.NEXT_PUBLIC_ASSETS_URL}/vp9.webm`;

type AstronautProps = {
    // 0 - 360
    rotation?: number;
    // 0.5 - 8
    speed?: number;
};

const Astronaut = ({rotation, speed}: AstronautProps) => {
    const ref = useRef<HTMLVideoElement>(null);
    const desiredRotation = useRef<number | null>(null);
    const baseSpeed = useRef<number>(1);
    const isInView = useInView(ref);

    useEffect(() => {
        if (ref.current) {
            ref.current.playbackRate = baseSpeed.current;

            let handle: number | null = null;

            const onTimeUpdate = () => {
                if (ref.current) {
                    ref.current.play().catch(() => {});

                    if (desiredRotation.current !== null) {
                        const currentRotation =
                            ref.current?.currentTime / ref.current.duration + 0.05;

                        const distance =
                            desiredRotation.current -
                            currentRotation +
                            (desiredRotation.current > currentRotation ? 0 : 1);

                        if (distance < 0.01) {
                            desiredRotation.current = null;
                            ref.current.playbackRate = baseSpeed.current;
                        } else {
                            const rate =
                                distance * 5 * baseSpeed.current + baseSpeed.current;

                            const delta = rate - ref.current.playbackRate;

                            // easing
                            const nextRate =
                                ref.current.playbackRate +
                                Math.min(
                                    Math.abs(delta) * (0.97 - Math.abs(distance)),
                                    0.05,
                                ) *
                                    Math.sign(delta);

                            // console.log(
                            //     nextRate - ref.current.playbackRate,
                            //     rotation,
                            //     desiredRotation.current,
                            //     distance,
                            //     rate,
                            //     delta,
                            // );

                            try {
                                if (Number.isFinite(nextRate)) {
                                    ref.current.playbackRate = Math.max(
                                        Math.min(nextRate, 8),
                                        0.5,
                                    );
                                }
                            } catch (e) {
                                console.warn(e);
                            }

                            if (ref.current?.paused) {
                                ref.current.play().catch(() => {});
                            }
                        }
                    }
                }

                handle = requestAnimationFrame(onTimeUpdate);
            };

            handle = requestAnimationFrame(onTimeUpdate);

            return () => {
                cancelAnimationFrame(handle!);
            };
        }
    }, []);

    const interpolateRotation = useCallback((newRotation: number | undefined) => {
        if (ref.current && newRotation !== null && newRotation !== undefined) {
            desiredRotation.current = newRotation / 360;
        }
    }, []);

    const interpolateSpeed = useCallback((newSpeed: number | undefined) => {
        return new Promise((resolve) => {
            if (ref.current && newSpeed !== null && newSpeed !== undefined) {
                ref.current.play().catch(() => {});

                if (ref.current.playbackRate === newSpeed) {
                    resolve(newSpeed);
                } else {
                    ease({
                        startValue: ref.current.playbackRate,
                        endValue: newSpeed,
                        durationMs: 2000,
                        onStep: (value: number) => {
                            if (desiredRotation.current === null && ref.current) {
                                ref.current.playbackRate = value;
                            }
                        },
                        onComplete: () => {
                            // baseSpeed.current = newSpeed;
                            resolve(newSpeed);
                        },
                    });
                }
            } else {
                resolve(newSpeed);
            }
        });
    }, []);

    useEffect(() => {
        if (ref.current) {
            if (isInView && ref.current.paused) {
                ref.current.play().catch(() => {});
            }

            if (!isInView && !ref.current.paused) {
                ref.current.pause();
            }
        }
    }, [isInView]);

    useEffect(() => {
        if (isInView) {
            interpolateRotation(rotation);
        }
    }, [rotation, isInView]);

    useEffect(() => {
        if (desiredRotation.current !== null) {
            setTimeout(() => {
                interpolateSpeed(speed);
            }, 6000);
        }
    }, [speed]);

    return (
        <AstronautVideo
            ref={ref}
            width="720"
            height="100%"
            loop
            muted
            playsInline
            preload="auto"
        >
            <source src={ASTRONAUT_HEVC_URL} type='video/mp4; codecs="hvc1"' />
            <source src={ASTRONAUT_VP9_URL} type="video/webm" />
        </AstronautVideo>
    );
};

export default Astronaut;
