import {useIsPresent} from 'framer-motion';
import {memo, useMemo, useState} from 'react';

import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import {Paragraph} from '~components/atoms/Text/styles';
import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {
    ButtonsContainer,
    InfoWrapper,
    InfosContainer,
    SpecialElement,
    SpecialElementsContainer as SpecialElementsContainer,
    Text,
    Title,
    Name,
    RarityType,
    Quote,
    TagsContainer,
    Tag,
} from './styles';

const BASE_DELAY = 0.5;

type SpecialElementsProps = {};

type SpecialElementType = {
    shorthand?: string;
    name: string;
    rarity: 'BASIC' | 'SPECIAL';
    quote?: string;
    tags?: string[];
    description: string;
};

const SpecialElements = ({}: SpecialElementsProps) => {
    const {t} = useScopeTranslation('homepage', 'specialElements');

    const [selectedIndex, setSelectedIndex] = useState(0);

    const isPresent = useIsPresent();

    const specialElements = useMemo(
        () => t('specialElements', {returnObjects: true}) as SpecialElementType[],
        [t],
    );

    return (
        <SpecialElementsContainer>
            <MobileScreenScrollDetector screen="special-elements" />
            <Title
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: isPresent ? BASE_DELAY + 0.5 : 0,
                    ease: 'easeOut',
                    duration: 1,
                }}
            >
                {t('title')}
            </Title>
            <Text
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: isPresent ? BASE_DELAY + 1 : 0.25,
                    ease: 'easeOut',
                    duration: 1,
                }}
            >
                {t('text')}
            </Text>

            <ButtonsContainer>
                {specialElements.map(({shorthand, name}, i) => (
                    <SpecialElement
                        key={name}
                        disabled={selectedIndex === i}
                        onClick={() => setSelectedIndex(i)}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0,
                            y: '-100%',
                        }}
                        transition={{
                            ease: 'easeOut',
                            duration: 0.3,
                            delay: isPresent ? BASE_DELAY + 0.5 + i * 0.2 : 0,
                        }}
                    >
                        {shorthand || name}
                    </SpecialElement>
                ))}
            </ButtonsContainer>

            <InfosContainer
                initial={{
                    opacity: 0,
                    y: 100,
                }}
                animate={{
                    opacity: 1,
                    y: 0,
                }}
                exit={{
                    y: -200,
                    opacity: 0,
                }}
                transition={{
                    duration: 0.5,
                    ease: 'easeInOut',
                    delay: isPresent ? BASE_DELAY + 2 : 0,
                }}
            >
                {specialElements.map(
                    ({name, rarity, quote, tags, description}, i) => (
                        <InfoWrapper
                            key={name}
                            initial={false}
                            animate={
                                selectedIndex === i
                                    ? {
                                          opacity: 1,
                                          y: 0,
                                      }
                                    : {
                                          opacity: 0,
                                          y: 100,
                                      }
                            }
                            transition={{
                                duration: 0.3,
                                ease: 'easeInOut',
                                delay: selectedIndex === i ? 0.3 : 0,
                            }}
                            mobile={true}
                        >
                            <Name>{name}</Name>
                            <RarityType>
                                {t('rarityType')}
                                {'\u00a0\u00a0\u00a0'}
                                <b>{rarity}</b>
                            </RarityType>
                            {quote && <Quote>{quote}</Quote>}
                            {tags?.length && (
                                <TagsContainer>
                                    {tags?.map((tag) => (
                                        <Tag key={tag}>
                                            {'/'}
                                            {tag}
                                        </Tag>
                                    ))}
                                </TagsContainer>
                            )}
                            <Paragraph>{description}</Paragraph>
                        </InfoWrapper>
                    ),
                )}
            </InfosContainer>
        </SpecialElementsContainer>
    );
};

export default memo(SpecialElements);
