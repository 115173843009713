import {AnimatePresence, useIsPresent} from 'framer-motion';
import {memo, useEffect, useMemo, useState} from 'react';

import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import RWDHelper from '~components/atoms/RWDHelper';
import Bubbles, {BubblesConfig} from '~components/molecules/Bubbles';
import {useBreakpoints} from '~components/providers/BreakpointsProvider';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {BUBBLES_CONFIG, MOBILE_BUBBLES_CONFIG} from './config';
import {MobileBubbleContainer, SuperpowersContainer, Text, Title} from './styles';

const BASE_DELAY = 0.5;

type SuperpowersProps = {};

const Superpowers = ({}: SuperpowersProps) => {
    const {t} = useScopeTranslation('homepage', 'superpowers');

    const isPresent = useIsPresent();

    const {screens, screenIndex} = useScrollNavigation();
    const [isVisibleOnce, setIsVisibleOnce] = useState(false);

    const superpowers = useMemo(
        () => t('superpowers', {returnObjects: true}) as string[],
        [t],
    );

    const breakpoints = useBreakpoints();

    const bubbles = useMemo<BubblesConfig>(
        () =>
            (breakpoints.laptop ? BUBBLES_CONFIG : MOBILE_BUBBLES_CONFIG).map(
                (bubble, i) => ({
                    ...bubble,
                    children: superpowers[i % superpowers.length],
                }),
            ),
        [superpowers, breakpoints.laptop],
    );

    useEffect(() => {
        if (!isVisibleOnce) {
            if (screenIndex >= screens.indexOf('superpowers')) {
                setIsVisibleOnce(true);
            }
        }
    }, [screenIndex]);

    return (
        <SuperpowersContainer>
            <MobileScreenScrollDetector screen="superpowers" />
            <Title
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: isPresent ? BASE_DELAY + 0.5 : 0,
                    ease: 'easeOut',
                    duration: 1,
                }}
            >
                {t('title')}
            </Title>
            <Text
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: isPresent ? BASE_DELAY + 1 : 0.25,
                    ease: 'easeOut',
                    duration: 1,
                }}
            >
                {t('text')}
            </Text>
            <RWDHelper
                desktop={<Bubbles bubbles={bubbles} baseDelay={0} />}
                mobile={
                    <MobileBubbleContainer>
                        <AnimatePresence>
                            {isVisibleOnce && (
                                <Bubbles baseDelay={-1} bubbles={bubbles} />
                            )}
                        </AnimatePresence>
                    </MobileBubbleContainer>
                }
            />
        </SuperpowersContainer>
    );
};

export default memo(Superpowers);
