import React, {ComponentProps} from 'react';

import Astronaut from '~components/atoms/Astronaut';
import Button from '~components/atoms/Button';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import RWDHelper from '~components/atoms/RWDHelper';
import ShootingStars from '~components/atoms/ShootingStars';
import SpaceBackground from '~components/atoms/SpaceBackground';
import {StatItemType} from '~components/atoms/StatsGrid';
import {Routes} from '~config/routes';
import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {astronautConfig} from '../../HomepageAstronaut';

import {
    AboutUsContainer,
    AstronautContainer,
    LeftColumn,
    LeftText,
    MiddleContainer,
    PortalContainer,
    RightText,
    StyledStatsGrid,
    Title,
} from './styles';

type AboutUsProps = {};

const BASE_DELAY = 2;

const spaceBackgroundOptions: ComponentProps<typeof SpaceBackground>['options'] = {
    particles: {
        number: {
            value: 500,
            limit: 1000,
            density: {
                enable: true,
                area: 500,
            },
        },
        size: {
            value: 1.3,
        },
    },
};

const AboutUs = ({}: AboutUsProps) => {
    const {t} = useScopeTranslation('homepage', 'aboutUs');

    return (
        <AboutUsContainer
            exit={{
                y: '-100%',
            }}
            transition={{
                duration: 1,
                ease: 'easeIn',
            }}
        >
            <MobileScreenScrollDetector screen="about-us" />

            <LeftColumn>
                <Title
                    initial={{opacity: 0, y: '100%'}}
                    animate={{opacity: 1, y: '0%'}}
                    transition={{
                        delay: BASE_DELAY + 0,
                        ease: 'easeOut',
                    }}
                >
                    {t('title')}
                </Title>
                <LeftText
                    initial={{opacity: 0, y: '100%'}}
                    animate={{opacity: 1, y: '0%'}}
                    transition={{
                        delay: BASE_DELAY + 0.25,
                        ease: 'easeOut',
                    }}
                >
                    {t('paragraph')}
                    {t('button') && (
                        <Button href={Routes.Mint}>{t('button')}</Button>
                    )}
                </LeftText>

                <RWDHelper
                    mobile={
                        <MiddleContainer>
                            <PortalContainer>
                                <SpaceBackground
                                    options={spaceBackgroundOptions}
                                    id="about-us-space-background"
                                />
                            </PortalContainer>
                            <AstronautContainer>
                                <Astronaut {...astronautConfig['about-us']} />
                            </AstronautContainer>
                        </MiddleContainer>
                    }
                />

                <RightText
                    initial={{opacity: 0, y: '100%'}}
                    animate={{opacity: 1, y: '0%'}}
                    transition={{
                        delay: BASE_DELAY + 0.5,
                        ease: 'easeOut',
                    }}
                >
                    {t('rightText')}
                </RightText>

                <StyledStatsGrid
                    initial={{opacity: 0, y: '100%'}}
                    animate={{opacity: 1, y: '0%'}}
                    transition={{
                        delay: BASE_DELAY + 0.75,
                        ease: 'easeOut',
                    }}
                    items={t('stats', {returnObjects: true}) as StatItemType[]}
                />
            </LeftColumn>
            <ShootingStars
                duration={3}
                delay={BASE_DELAY - 1}
                color="invert"
                key="shooting-stars-about-us"
            />
        </AboutUsContainer>
    );
};

export default AboutUs;
