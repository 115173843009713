import {ReactNode} from 'react';
import {useIsPresent} from 'framer-motion';
import {useCallback, useEffect, useRef, useState} from 'react';

import {Bubble, BubblesContainer, BubbleWrapper, Orbit, Planet} from './styles';

export type BubblesConfig = {
    x: number;
    y: number;
    type?: 'black' | 'planet';
    withOrbit?: boolean;
    children?: ReactNode;
    delay?: number;
}[];

type BubbleStates = 'float' | 'pop';

type BubblesProps = {
    bubbles: BubblesConfig;
    baseDelay?: number;
};

const Bubbles = ({bubbles, baseDelay = 0}: BubblesProps) => {
    const isPresent = useIsPresent();

    const audioRefs = useRef<HTMLAudioElement[]>();

    const [bubbleStates, setBubbleStates] = useState<BubbleStates[]>(() =>
        Array(bubbles.length).fill('float'),
    );

    const setBubbleState = useCallback((index: number, newState: BubbleStates) => {
        setBubbleStates((current) => {
            const temp = [...current];
            temp[index] = newState;
            return temp;
        });

        if (newState === 'pop') {
            if (audioRefs.current?.length) {
                const audioRef = audioRefs.current[index];

                if (
                    [
                        HTMLMediaElement.HAVE_FUTURE_DATA,
                        HTMLMediaElement.HAVE_ENOUGH_DATA,
                    ].includes(audioRef.readyState)
                ) {
                    audioRef.currentTime = 0;
                    audioRef.volume = 0.1;
                    audioRef.play().catch(() => {});
                }
            }
        }
    }, []);

    const popBubble = useCallback(
        (index: number) => {
            if (!isPresent) {
                return;
            }
            setBubbleState(index, 'pop');
            setTimeout(() => setBubbleState(index, 'float'), 2000);
        },
        [isPresent, setBubbleState],
    );

    useEffect(() => {
        audioRefs.current = Array.from(Array(bubbleStates.length)).map(
            (_, i) => new Audio(`/assets/audio/pop/${(i % 4) + 1}.mp3`),
        );
    }, [bubbleStates.length]);

    return (
        <BubblesContainer>
            {bubbles.map(({x, y, type = 'black', withOrbit, children, delay}, i) => {
                const state = bubbleStates[i];

                const initial = {
                    y:
                        ((typeof window === 'object'
                            ? (window as Window).innerHeight
                            : undefined) || 1000) +
                        200 +
                        y,
                    x: x + 100 * (Math.random() < 0.5 ? -1 : 1),
                    opacity: 1,
                    scale: 1,
                };

                return (
                    <BubbleWrapper
                        key={`${x}_${y}`}
                        initial={initial}
                        animate={
                            state === 'float'
                                ? {
                                      ...initial,
                                      y: y + 150,
                                      x: x + 150,
                                  }
                                : {
                                      ...initial,
                                      scale: 1.5,
                                      opacity: 0,
                                  }
                        }
                        exit={{
                            y: -1500,
                            x: x + 50 * (i % 2 ? 1 : -1),
                            opacity: 0,
                            scale: 0.7,
                        }}
                        transition={{
                            default: {
                                ease: 'easeOut',
                                duration: isPresent ? 4 : 2,
                                delay: isPresent
                                    ? state === 'float'
                                        ? baseDelay +
                                          (delay ?? Math.max(i - 6, 0) * 0.5)
                                        : 0.3
                                    : 0,
                            },
                            opacity: {
                                duration: isPresent
                                    ? state === 'float'
                                        ? 0
                                        : 0.2
                                    : 1,
                                delay: 0,
                                ease: 'easeInOut',
                            },
                            scale: {
                                duration: isPresent
                                    ? state === 'float'
                                        ? 0
                                        : 0.3
                                    : 1,
                                delay: 0,
                                ease: 'backInOut',
                            },
                        }}
                        style={{zIndex: type === 'planet' ? 0 : 1}}
                        mobile={true}
                    >
                        {type === 'planet' ? (
                            <Planet onClick={() => popBubble(i)} />
                        ) : (
                            <Bubble
                                style={{
                                    animationDelay: `${i * 20}ms`,
                                }}
                                onMouseEnter={() => popBubble(i)}
                                onClick={() => popBubble(i)}
                            >
                                {children}
                            </Bubble>
                        )}
                        {withOrbit && <Orbit />}
                    </BubbleWrapper>
                );
            })}
        </BubblesContainer>
    );
};

export default Bubbles;
