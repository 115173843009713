import styled, {createGlobalStyle} from 'styled-components';

import {above, below} from '~config/breakpoints';

export const PortalWrapper = styled.div`
    position: fixed;
    inset: 0;
    inset-inline: max(calc((100vw - 3840px) / 2), 0px);
    z-index: -1;
`;

export const GlobalStyle = createGlobalStyle<{bgColor: string}>`
  body {
    overflow: hidden;

    &::after {
        content: '';
        z-index: 9999;
        position: fixed;
        inset: 0;
        background: ${({bgColor}) => bgColor};
        pointer-events: none;
        transition: opacity 750ms ease-in;
        
        /* ${above.laptop} { */
            transition-delay: 750ms;
        /* } */
    }

    &.loaded {
        ${below.laptop} {
            overflow: initial;
        }


        &::after {
            opacity: 0;
        }
    }
  }
`;
