import styled from 'styled-components';

export const SpaceBackgroundContainer = styled.div`
    inset: 0;
    position: absolute;
    background: black;

    & > * {
        height: 100% !important;
    }
`;
