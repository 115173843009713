import Head from 'next/head';

type HtmlHeadProps = {
    title: string;
};

const HtmlHead = ({title}: HtmlHeadProps) => {
    return (
        <Head>
            <meta charSet="utf-8" />
            <title>{title}</title>

            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <link rel="stylesheet" href="https://use.typekit.net/zpc5vbz.css" />
        </Head>
    );
};

export default HtmlHead;
