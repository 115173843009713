import {useIsPresent} from 'framer-motion';
import Image from 'next/image';
import {ComponentProps} from 'react';

import Motion from '~components/atoms/Motion';

import {Description, InfoWithImageContainer, Title} from './styles';

export type InfoWithImageProps = ComponentProps<typeof InfoWithImageContainer> & {
    imageUrl?: string;
    title?: string;
    description?: string;
    delay?: number;
};

const InfoWithImage = ({
    imageUrl,
    title,
    description,
    delay = 0,
    ...props
}: InfoWithImageProps) => {
    const isPresent = useIsPresent();

    return (
        <InfoWithImageContainer {...props}>
            {imageUrl && (
                <Motion.div
                    initial={{opacity: 0, y: '100%'}}
                    animate={{
                        opacity: 1,
                        y: '0%',
                    }}
                    exit={{opacity: 0, y: '-100%'}}
                    transition={{
                        delay: delay,
                        ease: 'easeOut',
                        duration: 0.5,
                    }}
                    mobile={{
                        initial: {
                            opacity: 0,
                        },
                        animate: false,
                        whileInView: {
                            opacity: 1,
                        },
                        transition: {
                            ease: 'easeOut',
                            duration: 1,
                            delay: 0.5,
                        },
                        viewport: {
                            once: true,
                        },
                    }}
                >
                    <Image
                        src={imageUrl}
                        layout="fixed"
                        width={81}
                        height={81}
                        objectFit="contain"
                    />
                </Motion.div>
            )}
            {title && (
                <Title
                    initial={{opacity: 0, y: '100%'}}
                    animate={{
                        opacity: 1,
                        y: '0%',
                    }}
                    exit={{opacity: 0, y: '-100%'}}
                    transition={{
                        delay: delay + (isPresent ? 0.5 : 0),
                        ease: 'easeOut',
                        duration: 0.5,
                    }}
                >
                    {title}
                </Title>
            )}
            <Description
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: delay + (isPresent ? 0.5 : 0),
                    ease: 'easeOut',
                    duration: 0.5,
                }}
            >
                {description}
            </Description>
        </InfoWithImageContainer>
    );
};

export default InfoWithImage;
