import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {StyledButton} from '~components/atoms/Button/styles';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';
import {OrbitsContainer} from '~components/atoms/Orbits/styles';

export const LaunchSectionContainer = styled(Motion.section)`
    position: relative;
    clip-path: polygon(0% 0%, 100vw 0%, 100vw 100%, 0% 100%);

    ${above.laptop} {
        display: flex;
        justify-content: center;
        align-items: start;
    }
`;

export const RightText = styled(Paragraph)`
    ${above.laptop} {
        position: absolute;
        right: 100px;
        top: 180px;
        max-width: 200px;
    }
`;

export const BottomLeftContainer = styled(Motion.div)`
    position: absolute;
    bottom: 75px;
    left: 40px;
    min-height: 150px;

    ${below.laptop} {
        display: none;
    }
`;

export const LeftText = styled(Paragraph)`
    max-width: 240px;
    margin: 18px 0 22px;
`;

export const InputContainer = styled.div`
    display: flex;
    width: 340px;

    ${StyledButton} {
        flex-shrink: 0;
        white-space: nowrap;
    }
`;

export const CountdownContainer = styled(Motion.div)`
    margin-top: 190px;
    font-size: 80px;
    max-height: max-content;
    display: flex;
    justify-content: center;

    ${below.laptop} {
        margin-top: 60px;
        font-size: 7.5vw;
    }
`;

export const AstronautContainer = styled.div`
    position: relative;
    height: 70vh;
    width: 100%;
    margin: 60px 0 0px;
    z-index: -1;

    ${OrbitsContainer} {
        transform: translateX(44vw);
    }
`;

export const CountdownCompletedContainer = styled.div`
    margin-top: 80px;

    ${StyledButton} {
        width: 250px;
    }
`;
