import React, {
    memo,
    ReactNode,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {Car, Lane} from './styles';

type InfiniteAnimatedSliderProps = {
    children: ReactNode;
    direction: 'up' | 'down' | 'left' | 'right';
    pixelsPerSecond: number;
    paused?: boolean;
    pauseOnHover?: boolean;
    childrenMultiplier?: number;
};

const InfiniteAnimatedSlider = ({
    children,
    direction,
    pixelsPerSecond,
    paused,
    pauseOnHover,
    childrenMultiplier,
}: InfiniteAnimatedSliderProps) => {
    const axis = useMemo(
        () => (['left', 'right'].includes(direction) ? 'horizontal' : 'vertical'),
        [direction],
    );

    const carRef = useRef<HTMLDivElement>(null);

    const [animationDuration, setAnimationDuration] = useState(0);

    const duplicatedChildren = useMemo(
        () => (
            <>
                {/* Make sure to fill container */}
                {/* FIXME: make this configurable */}
                {Array.from(Array(childrenMultiplier || 1)).map((_, i) => (
                    <React.Fragment key={i}>{children}</React.Fragment>
                ))}
            </>
        ),
        [children, childrenMultiplier],
    );

    useLayoutEffect(() => {
        if (carRef.current) {
            setAnimationDuration(
                Math.round(
                    (carRef.current[
                        axis === 'horizontal' ? 'offsetWidth' : 'offsetHeight'
                    ] /
                        pixelsPerSecond) *
                        1000,
                ),
            );
        }
    }, [pixelsPerSecond, duplicatedChildren]);

    return (
        <Lane
            direction={direction}
            axis={axis}
            animationDuration={animationDuration}
            paused={paused}
            pauseOnHover={pauseOnHover}
        >
            <Car ref={carRef}>{duplicatedChildren}</Car>
            <Car>{duplicatedChildren}</Car>
        </Lane>
    );
};

export default memo(InfiniteAnimatedSlider);
