/* eslint-disable camelcase */
import {useInView} from 'framer-motion';
import merge from 'lodash.merge';
import {memo, useCallback, useEffect, useRef} from 'react';
import {ParticlesProps} from 'react-tsparticles';
import type {Container} from 'tsparticles-engine';
import {loadFull} from 'tsparticles';
import dynamic from 'next/dynamic';

import {SpaceBackgroundContainer} from './styles';

const Particles = dynamic(() => import('react-tsparticles'), {
    ssr: false,
});

const defaultOptions: ParticlesProps['options'] = {
    fullScreen: false,
    fpsLimit: 30,
    particles: {
        number: {
            value: 500,
            limit: 1000,
            density: {
                enable: true,
                area: 1000,
            },
        },
        color: {
            value: [
                '#ffffff',
                '#FEF7FF',
                '#382288',
                '#356972',
                '#bdd9f4',
                '#a0e2ff',
                '#f7f3cb',
                '#ffe282',
                '#ff9492',
                '#eff1f5',
            ],
        },
        shape: {
            type: 'circle',
            stroke: {
                width: 0,
                color: '#000000',
            },
            polygon: {
                nb_sides: 5,
            },
        },
        opacity: {
            value: 1,
            random: true,
            anim: {
                enable: true,
                speed: 0.4,
                opacity_min: 0,
                sync: false,
            },
        },
        size: {
            value: 1.6,
            random: true,
            anim: {
                enable: true,
                speed: 1.5,
                size_min: 0,
                sync: false,
            },
        },
    },
    detectRetina: false,
};

const init: ParticlesProps['init'] = async (main) => {
    await loadFull(main);
};

type SpaceBackgroundProps = {
    options?: Partial<ParticlesProps['options']>;
    id?: string;
};

const SpaceBackground = ({options, id}: SpaceBackgroundProps) => {
    const containerRef = useRef(null);
    const isInView = useInView(containerRef);
    const ref = useRef<Container | undefined>();

    useEffect(() => {
        if (ref.current) {
            if (isInView) {
                ref.current.play();
            } else {
                ref.current.pause();
            }
        }
    }, [isInView]);

    const handleLoaded = useCallback(async (container: Container | undefined) => {
        ref.current = container;
    }, []);

    return (
        <SpaceBackgroundContainer ref={containerRef}>
            <Particles
                id={id}
                options={merge({}, defaultOptions, options)}
                init={init}
                loaded={handleLoaded}
            />
        </SpaceBackgroundContainer>
    );
};

export default memo(SpaceBackground);
