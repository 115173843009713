import styled from 'styled-components';

import {StyledButton} from '~components/atoms/Button/styles';
import Motion from '~components/atoms/Motion';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';

export const GalleryContainer = styled(Motion.section)`
    ${above.laptop} {
        padding-left: 40px !important;
        will-change: transform;
    }

    ${below.laptop} {
        margin: 90px 0;
    }
`;

export const Text = styled(Paragraph)`
    ${above.laptop} {
        max-width: 240px;
        margin-top: 15vh;
    }

    ${StyledButton} {
        display: block;
        margin-top: 32px;
        max-width: max-content;
        min-width: 160px;
        cursor: pointer;
    }
`;

export const SliderContainer = styled(Motion.div)`
    position: fixed;
    top: 0;
    bottom: -75px;
    left: 324px;
    width: 324px;
    overflow: hidden;

    &:last-of-type {
        bottom: -300px;
        left: calc(324px * 2 + 91px);
    }
`;

export const ColumnContainer = styled.div`
    width: 100%;

    img {
        display: block;
        margin-top: 91px;
        object-fit: cover;
    }
`;

export const RowContainer = styled.div`
    height: 100%;
    display: flex;
    gap: 13px;
    margin-right: 13px;
`;

export const MobileSliderContainer = styled.div`
    height: 404px;
    margin-top: 60px;
`;
