import styled, {css} from 'styled-components';

import {Paragraph} from '~components/atoms/Text/styles';
import {below} from '~config/breakpoints';

export const VideoContainer = styled.div<{isFullscreen: boolean}>`
    display: grid;
    gap: 28px;
    width: 240px;

    ${below.laptop} {
        gap: 24px;
    }

    video {
        ${({isFullscreen}) =>
            isFullscreen
                ? css`
                      width: 100%;
                      height: 100%;
                  `
                : css`
                      display: none;
                  `}
    }
`;

export const Description = styled(Paragraph)``;

export const Row = styled.button`
    height: 79px;
    width: 100%;
    display: flex;
`;

export const PlayButton = styled.div`
    height: 100%;
    width: 79px;
    flex-shrink: 0;
    position: relative;
    background: url('/assets/bg/bw-space-background.png');
    background-size: cover;

    &:hover {
        &::before {
            background-color: var(--hover-color);
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 100%;
        --size: 39px;
        width: var(--size);
        height: var(--size);
        transition: background-color ease-in-out 250ms;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 11.5px;
        background: black;
        clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
        color: black;
    }
`;

export const ThumbnailContainer = styled.div`
    flex-grow: 1;
    height: 100%;
    position: relative;
`;
