import styled, {css} from 'styled-components';

export const FooterLinksContainer = styled.div<{absolute?: boolean}>`
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 16px 20px;
    text-align: left;
    width: 100%;

    ${({absolute}) =>
        absolute &&
        css`
            position: absolute;
            bottom: 18px;
            inset-inline: 0;
            max-width: min(1000px, 100vw);
            margin: 0 auto;
            padding: 0 16px;
        `}
`;

export const Copyright = styled.span`
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    margin-right: auto;
    letter-spacing: 0.2px;
`;

export const Link = styled.a`
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2px;
`;
