import {useScrollNavigation} from '~components/providers/ScrollNavigation';

import {GridBackgroundContainer} from './styles';

type GridBackgroundProps = {};

const GridBackground = ({}: GridBackgroundProps) => {
    const {screenIndex} = useScrollNavigation() || {screenIndex: 0};

    return (
        <GridBackgroundContainer
            style={{
                backgroundPositionY: `${screenIndex * -50}px`,
            }}
        ></GridBackgroundContainer>
    );
};

export default GridBackground;
