import {memo, ReactElement, useEffect, useState} from 'react';

import {useBreakpoints} from '~components/providers/BreakpointsProvider';

type RWDHelperProps = {
    mobile?: ReactElement | null;
    desktop?: ReactElement | null;
    ssr?: boolean;
};

const RWDHelper = ({mobile, desktop, ssr = true}: RWDHelperProps) => {
    const breakpoints = useBreakpoints();

    const [isMobile, setIsMobile] = useState(
        ssr === false
            ? typeof window === 'undefined'
                ? undefined
                : !breakpoints.laptop
            : false,
    );

    useEffect(() => {
        // Must be in useEffect to prevent hydration mismatch
        setIsMobile(!breakpoints.laptop);
    }, [breakpoints]);

    if (isMobile) {
        return mobile ?? null;
    } else if (isMobile === false) {
        return desktop ?? null;
    } else {
        return null;
    }
};

export default memo(RWDHelper);
