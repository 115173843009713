import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import StatsGrid from '~components/atoms/StatsGrid';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';

export const AboutUsContainer = styled(Motion.section)`
    position: relative;
`;

export const LeftColumn = styled.div`
    ${above.laptop} {
        display: flex;
        flex-direction: column;
        padding: 10vh 0 0;
        height: 100%;
        max-width: 430px;
    }

    ${below.laptop} {
        padding: 50px 0 0;
    }
`;

export const Title = styled(Motion.h1)`
    font-weight: 600;

    text-align: left;

    ${above.laptop} {
        max-width: 33vw;
        font-size: 109px;
        line-height: 139px;
    }

    ${below.laptop} {
        font-size: 85px;
        line-height: 100px;
    }

    @media (min-width: 2200px) {
        max-width: 350px;
    }
`;

export const LeftText = styled(Paragraph)`
    display: grid;
    justify-items: left;

    ${above.laptop} {
        margin: 16px 40px;
        max-width: 240px;
        gap: 32px;
    }

    ${below.laptop} {
        margin: 16px 0;
        gap: 24px;
    }

    @media (min-width: 3400px) {
        max-width: 200px;
    }
`;

export const RightText = styled(Paragraph)`
    ${above.laptop} {
        max-width: 200px;
        position: absolute;
        top: 150px;
        right: 85px;
    }

    ${below.laptop} {
        margin: 32px 0 24px;
    }
`;

export const StyledStatsGrid = styled(StatsGrid)`
    ${above.laptop} {
        margin: auto 40px 0;
    }
`;

export const MiddleContainer = styled.div`
    height: 620px;
    margin: 20px 0;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 50px 20px 100px;
`;

export const PortalContainer = styled.div`
    position: relative;
    width: 56vw;
`;

export const AstronautContainer = styled.div`
    position: absolute;
    bottom: 0px;
    height: 60vh;
    transform: translateX(100px);
`;
