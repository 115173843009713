import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {above, below} from '~config/breakpoints';

export const BannerContainer = styled(Motion.div)`
    position: relative;
    background-color: black;
    background: url('assets/bg/bw-space-background.png');
    color: white;
    z-index: 6;

    ${above.laptop} {
        position: fixed;
        margin-top: 100px;
        left: 0;
        right: 0;
        padding: 53px var(--right-padding-with-scrollnav) 50px
            calc(var(--h-padding) + 40px);
    }

    ${below.laptop} {
        width: 100vw;
        padding: 26px var(--h-padding) 40px;
    }
`;

export const Text = styled(Motion.div)`
    font-weight: 500;
    font-size: 34px;
    line-height: 43px;
    letter-spacing: 0.2px;
    max-width: 1250px;

    ${below.laptop} {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.2px;
    }
`;
