import Motion from '~components/atoms/Motion';
import Banner from '~components/atoms/Banner';
import Video from '~components/molecules/Video';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import {useScopeTranslation} from '~hooks/useScopeTranslation';
import {useBreakpoints} from '~components/providers/BreakpointsProvider';
import RWDHelper from '~components/atoms/RWDHelper';
import Astronaut from '~components/atoms/Astronaut';
import Orbits, {OrbitsProps} from '~components/atoms/Orbits';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import {orbitsConfig} from '~components/organisms/Homepage';

import {AstronautContainer, OurMissionContainer} from './styles';

const BASE_DELAY = 1;

export const OUR_MISSION_BANNER_ID = 'our-mission-banner';

type OurMissionProps = {};

const OurMission = ({}: OurMissionProps) => {
    const {t} = useScopeTranslation('homepage', 'ourMission');
    const {screen, previous} = useScrollNavigation();

    const breakpoints = useBreakpoints();

    return (
        <OurMissionContainer>
            <MobileScreenScrollDetector screen="our-mission" />
            <RWDHelper
                mobile={
                    <AstronautContainer>
                        <Orbits {...(orbitsConfig['our-mission'] as OrbitsProps)} />
                        <Astronaut />
                    </AstronautContainer>
                }
            />
            <Motion.div
                style={{
                    margin: breakpoints.laptop ? 'auto 0 5px 40px' : '0 0 27px 0',
                }}
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: screen === 'character' ? 0 : 1,
                    y:
                        screen === 'our-mission'
                            ? 0
                            : screen === 'character'
                            ? '-85vh'
                            : -250,
                }}
                transition={{
                    duration: 1,
                    delay: previous === 'about-us' ? BASE_DELAY + 1 : 0,
                    ease: 'easeOut',
                }}
                exit={{y: '-90vh', opacity: 0}}
            >
                <Video
                    {...t('video', {returnObjects: true})}
                    url={`${process.env.NEXT_PUBLIC_ASSETS_URL}${t('video.url')}`}
                    descriptionDelay={
                        previous === 'about-us' ? BASE_DELAY + 1.25 : 0.5
                    }
                />
            </Motion.div>
            <Banner
                style={{bottom: '0'}}
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y:
                        screen === 'our-mission-quote'
                            ? '0%'
                            : screen === 'character'
                            ? 'calc(-100vh + 100% + 100px)'
                            : '100%',
                }}
                exit={{y: '-100vh'}}
                transition={{
                    delay: 0,
                    ease: 'easeOut',
                    duration: 1,
                }}
            >
                <p>{t('quote')}</p>
            </Banner>
        </OurMissionContainer>
    );
};

export default OurMission;
