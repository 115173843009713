import classNames from 'classnames';
import Link from 'next/link';
import {
    cloneElement,
    ComponentProps,
    isValidElement,
    ReactElement,
    useMemo,
} from 'react';

import Motion from '../Motion';

import {StyledButton, StyledButtonProps} from './styles';

type AnchorTagProps = ComponentProps<typeof Motion.a> & ComponentProps<typeof Link>;
type ButtonTagProps = ComponentProps<typeof Motion.button>;

type CommonProps = StyledButtonProps & {
    disabled?: boolean;
};

type ButtonProps = (AnchorTagProps | ButtonTagProps) & CommonProps;

const isAnchorTagProps = (
    props: AnchorTagProps | ButtonTagProps,
): props is AnchorTagProps => {
    return (props as AnchorTagProps).href !== undefined;
};

type WrapperProps = ComponentProps<typeof Link>;

const Wrapper = ({
    href,
    prefetch,
    replace,
    scroll,
    shallow,
    locale,
    children,
}: WrapperProps) => {
    if (href !== undefined && (typeof href !== 'string' || href.startsWith('/'))) {
        return (
            <Link
                href={href}
                prefetch={prefetch}
                replace={replace}
                scroll={scroll}
                shallow={shallow}
                locale={locale}
                passHref
            >
                {children}
            </Link>
        );
    }

    return (
        <>
            {isValidElement(children)
                ? cloneElement<HTMLAnchorElement>(
                      children as unknown as ReactElement<HTMLAnchorElement>,
                      {
                          href: href,
                          target: '_blank',
                      },
                  )
                : children}
        </>
    );
};

const Button = ({children, ...props}: ButtonProps) => {
    const isLink = isAnchorTagProps(props);

    const [linkProps, buttonProps] = useMemo(() => {
        const temp = {...props};

        const linkProps = [
            'href',
            'prefetch',
            'replace',
            'scroll',
            'shallow',
            'locale',
        ] as (keyof ComponentProps<typeof Link>)[];

        return [
            Object.fromEntries(
                // @ts-ignore
                Object.entries(temp).filter(([key]) => linkProps.includes(key)),
            ),
            Object.fromEntries(
                // @ts-ignore
                Object.entries(temp).filter(([key]) => !linkProps.includes(key)),
            ),
        ] as unknown as [WrapperProps, CommonProps];
    }, [props]);

    return (
        <Wrapper {...linkProps}>
            <StyledButton
                as={isLink ? 'a' : 'button'}
                {...buttonProps}
                // @ts-ignore
                className={classNames(
                    // @ts-ignore
                    buttonProps.className,
                    buttonProps.disabled && 'disabled',
                )}
            >
                {children}
            </StyledButton>
        </Wrapper>
    );
};

export default Button;
