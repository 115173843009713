import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';

export const TeamSectionContainer = styled(Motion.section)`
    ${above.laptop} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10vh 2vw;
    }

    ${below.laptop} {
        margin: 90px 0 80px;
    }
`;

export const TextContainer = styled(Motion.div)`
    ${above.laptop} {
        align-self: flex-end;
        justify-self: left;
        margin-left: 40px;
        max-width: 240px;
    }
`;

export const Title = styled(Motion.h3)`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 21px 0;
`;

export const Description = styled(Paragraph)``;

export const HashTagsContainer = styled(Motion.div)`
    display: flex;
    flex-wrap: wrap;
    gap: 6px 0;
    grid-column: 1;

    ${above.laptop} {
        justify-self: center;
        align-self: flex-start;
        max-width: 320px;
    }

    ${below.laptop} {
        transform: translateX(-3px);
        margin: 90px 40px 40px 0;
    }
`;

export const HashTag = styled(Motion.button)`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #000000;
    padding: 3px 7px 0;

    &:hover {
        color: var(--hover-color) !important;
    }

    &:disabled {
        pointer-events: none;
    }
`;

export const TeamContainer = styled(Motion.div)`
    grid-column: 2;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    ${above.laptop} {
        padding: 0 20vw 0 0;
        gap: 64px 30px;
        max-height: 100%;
        height: 100%;
    }

    ${below.laptop} {
        gap: 64px 50px;
        height: min-content;
        max-height: 1000px;
    }
`;

export const PersonContainer = styled(Motion.div)`
    max-width: 130px;
    margin-top: 0;

    ${below.laptop} {
        max-width: min(40vw, 130px);
    }
`;

export const ImageContainer = styled(Motion.div)`
    position: relative;
    --size: 81px;
    width: var(--size);
    height: var(--size);

    background: url('/assets/bg/texture.png'),
        linear-gradient(141.18deg, #cab8ff 10.07%, #c1ffd7 98.58%);

    margin-bottom: 29px;
`;

export const Name = styled.h5`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
`;

export const Position = styled.h6`
    text-align: left;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #111111;
    margin: 8px 0;
`;

export const SocialsContainer = styled.div`
    margin-top: 8px;
    display: grid;
    grid-auto-flow: column;
    gap: 15px;
    grid-auto-columns: min-content;

    a {
        color: #111111;

        &:hover {
            color: var(--hover-color);
        }
    }
`;

export const Spacer = styled.div`
    min-height: 6vh;

    ${below.laptop} {
        min-height: 150px;
    }
`;
