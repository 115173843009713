import styled from 'styled-components';

import Motion from '~components/atoms/Motion';

export const StatsGridContainer = styled(Motion.div)`
    display: flex;
    flex-wrap: wrap;
    gap: 42px 82px;
    overflow: hidden;
    color: inherit;
    max-width: max-content;
`;

export const StatItem = styled(Motion.div)`
    display: grid;
    gap: 5px;
    position: relative;
    grid-auto-rows: max-content;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        transform: translate(42px, 8px);
        height: 52px;
        width: 1px;
        background: currentColor;
    }
`;

export const StatValue = styled.span`
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    white-space: nowrap;
`;

export const StatLabel = styled.label`
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    white-space: nowrap;
`;
