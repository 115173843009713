import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {below} from '~config/breakpoints';

export const PreloaderContainer = styled(Motion.section)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 0 !important;

    ${below.laptop} {
        height: 100vh;
        background: black;
    }
`;

export const PreloaderText = styled(Motion.h2)`
    color: white;
    font-weight: 600;
    font-size: 40px;
    line-height: 135%;
    text-align: center;
    z-index: 1;

    ${below.laptop} {
        margin-top: 5vh;
    }
`;

export const ScrollDownContainer = styled(Motion.button)`
    position: absolute;
    bottom: 75px;
    display: grid;
    gap: 16px;
    color: white;
    justify-items: center;

    ${below.laptop} {
        display: none;
    }
`;

export const ScrollDownText = styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
`;
