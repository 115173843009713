import styled from 'styled-components';

export const WalletButtonContentContainer = styled.div`
    display: flex;
    align-items: center;

    & > svg:last-child {
        position: absolute;
        top: 11px;
        right: 14px;
    }
`;

export const Avatar = styled.div`
    --size: 19px;
    width: var(--size);
    height: var(--size);
    background: linear-gradient(180deg, #2700c5 0%, rgba(27, 126, 85, 0.47) 100%);
    border-radius: 100%;
    position: absolute;
    top: 11px;
    left: 14px;
`;

export const Address = styled.span`
    display: block;
    margin: 0 13px;
`;
