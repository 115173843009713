import {ComponentProps} from 'react';

import {StatItem, StatLabel, StatsGridContainer, StatValue} from './styles';

export type StatItemType = {
    value: string;
    label: string;
};

type StatsGridProps = ComponentProps<typeof StatsGridContainer> & {
    items: StatItemType[];
};

const StatsGrid = ({items, ...props}: StatsGridProps) => {
    return (
        <StatsGridContainer {...props}>
            {items.map(({value, label}) => (
                <StatItem key={`${value}_${label}`}>
                    <StatValue>{value}</StatValue>
                    <StatLabel>{label}</StatLabel>
                </StatItem>
            ))}
        </StatsGridContainer>
    );
};

export default StatsGrid;
