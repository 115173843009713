import {ComponentProps, ReactNode} from 'react';

import {BannerContainer, Text} from './styles';

type BannerProps = ComponentProps<typeof BannerContainer> & {
    children: ReactNode;
};

const Banner = ({children, ...props}: BannerProps) => {
    return (
        <BannerContainer {...props}>
            <Text>{children}</Text>
        </BannerContainer>
    );
};

export default Banner;
