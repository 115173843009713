import {AnimatePresence} from 'framer-motion';
import {memo, ReactNode} from 'react';

import AboutUs from '~components/organisms/Homepage/sections/AboutUs';
import Preloader from '~components/organisms/Homepage/sections/Preloader';
import Portal from '~components/molecules/Portal';
import {useScrollNavigation} from '~components/providers/ScrollNavigation';
import GridBackground from '~components/atoms/GridBackground';
import CursorGlow from '~components/atoms/CursorGlow';
import ScrollNav from '~components/atoms/ScrollNav';
import Orbits, {OrbitsProps} from '~components/atoms/Orbits';

import Footer from '../Footer';
import HamburgerMenu from '../HambugerMenu';

import {PortalWrapper} from './styles';
import AstronautWrapper from './HomepageAstronaut';
import OurMission from './sections/OurMission';
import Character from './sections/Character';
import CreationProcess from './sections/CreationProcess';
import Stats from './sections/Stats';
import Gallery from './sections/Gallery';
import Superpowers from './sections/Superpowers';
// import MetaverseSlideshow from './sections/MetaverseSlideshow';
import Team from './sections/Team';
import Launch from './sections/Launch';
import SpecialElements from './sections/SpecialElements';

export const screens = [
    'preloader',
    'about-us',
    'our-mission',
    'our-mission-quote',
    'character',
    'creation-process',
    'stats',
    'gallery',
    'superpowers',
    'special-elements',
    // 'metaverse-slideshow',
    'team',
    'launch',
    'footer',
] as const;

export type Screens = typeof screens[number];

const screensWithAstrounaut: Screens[] = [
    'about-us',
    'our-mission',
    'our-mission-quote',
    'character',
    'creation-process',
    'stats',
    'launch',
];

export const mobileMenuScreens: Screens[] = [
    'about-us',
    'our-mission',
    'creation-process',
    // 'metaverse-slideshow',
    'team',
    'footer',
];

export const orbitsConfig: Partial<Record<Screens, OrbitsProps>> = {
    'our-mission': {
        orbits: [
            {
                scale: 1,
                withPlanet: true,
            },
        ],
    },
    'our-mission-quote': {
        orbits: [
            {
                scale: 0.65,
            },
        ],
    },
    'creation-process': {
        orbits: [
            {
                scale: 1,
            },
            {
                scale: 0.65,
            },
        ],
    },
    stats: {
        orbits: [
            {
                scale: 1,
            },
            {
                scale: 0.65,
            },
        ],
    },
    launch: {
        orbits: [
            {
                scale: 1,
            },
            {
                scale: 0.65,
            },
        ],
    },
    footer: {
        orbits: [
            {
                scale: 1,
            },
            {
                scale: 0.65,
            },
        ],
    },
};

type HomepageProps = {};

const includeScreen =
    (screen: Screens) => (screens: Screens[], Component: ReactNode) =>
        screens.includes(screen) && Component;

const Homepage = ({}: HomepageProps) => {
    const {screen, screenIndex} = useScrollNavigation();
    const include = includeScreen(screen);

    return (
        <AnimatePresence>
            <GridBackground key="grid-background" />
            {include(
                ['preloader', 'about-us'],
                <PortalWrapper key="portal">
                    <Portal fullscreen={screen === 'preloader'} />
                </PortalWrapper>,
            )}
            {include(['preloader'], <Preloader key="preloader" />)}
            {include(['about-us'], <AboutUs key="about-us" />)}
            {include(
                ['our-mission', 'our-mission-quote', 'character'],
                <OurMission key="our-mission" />,
            )}
            {include(['character'], <Character key="character" />)}
            {include(
                ['creation-process', 'stats'],
                <CreationProcess key="creation-process" />,
            )}
            {include(['stats'], <Stats key="stats" />)}
            {include(['gallery', 'superpowers'], <Gallery key="gallery" />)}
            {include(['superpowers'], <Superpowers key="superpowers" />)}
            {include(
                ['special-elements'],
                <SpecialElements key="special-elements" />,
            )}
            {/*{include(['metaverse-slideshow'], <MetaverseSlideshow key="metaverse-slideshow" />)}*/}
            {include(['team'], <Team key="team" />)}
            {include(['launch', 'footer'], <Launch key="launch" />)}
            {include(['footer'], <Footer key="footer" />)}
            {include(screensWithAstrounaut, <AstronautWrapper key="astronaut" />)}

            {screen !== 'preloader' && (
                <ScrollNav
                    screens={screens}
                    screenIndex={screenIndex}
                    key="scroll-nav"
                />
            )}
            <CursorGlow key="cursor-glow" />
            {Object.keys(orbitsConfig).includes(screen) && (
                <Orbits {...(orbitsConfig[screen] as OrbitsProps)} key="orbits" />
            )}

            <HamburgerMenu
                screensToShow={mobileMenuScreens}
                hideOnDesktop={screen === 'preloader'}
                color={screen === 'preloader' ? 'white' : 'black'}
                background={
                    (['gallery', 'superpowers'] as Screens[]).includes(screen)
                        ? 'white'
                        : 'transparent'
                }
            />
        </AnimatePresence>
    );
};

export default memo(Homepage);
