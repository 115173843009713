import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {StatsGridContainer} from '~components/atoms/StatsGrid/styles';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';

export const Container = styled(Motion.div)`
    display: flex;
    color: white;

    ${above.laptop} {
        gap: 40px;
        min-height: 220px;
        align-items: center;

        justify-content: space-between;

        ${StatsGridContainer} {
            justify-content: flex-end;
        }
    }

    ${below.laptop} {
        flex-direction: column;
        gap: 60px;
        padding: 0 16px 17px 0;
        justify-content: flex-start;

        ${StatsGridContainer} {
            display: grid;
            grid-template-columns: repeat(2, auto);
        }
    }
`;

export const Text = styled(Paragraph)`
    color: inherit;

    ${above.laptop} {
        max-width: 240px;
    }
`;
