import {AnimatePresence} from 'framer-motion';
import Link from 'next/link';
import React, {memo} from 'react';

import {Screens} from '~components/organisms/Homepage';
import {ScrollNavigationContextType} from '~components/providers/ScrollNavigation';
import {Routes} from '~config/routes';
import {useScopeTranslation} from '~hooks/useScopeTranslation';

import {Index, Label, NavItem, ScrollNavContainer, Separator} from './styles';

type ScrollNavProps = Pick<ScrollNavigationContextType<Screens>, 'screens'> & {
    screenIndex: number | null;
};

const ScrollNav = ({screens, screenIndex}: ScrollNavProps) => {
    const {t} = useScopeTranslation('common');

    return (
        <ScrollNavContainer
            layout
            initial={{
                x: 400,
                opacity: 0,
            }}
            animate={{
                x: 0,
                opacity: 1,
            }}
            exit={{
                x: 400,
                opacity: 0,
            }}
            transition={{
                duration: 1,
                ease: 'easeInOut',
                delay: 0.5,
            }}
        >
            {(
                Object.entries(t('screens', {returnObjects: true})) as [
                    typeof screens[number],
                    string,
                ][]
            )
                .filter(([_, label]) => label)
                .map(([screen, label], i, arr) => (
                    <React.Fragment key={label}>
                        <Link
                            passHref
                            href={{
                                pathname: Routes.Homepage,
                                query: {
                                    screen: screen === screens[0] ? null : [screen],
                                },
                            }}
                        >
                            <NavItem>
                                <Label>{label}</Label>
                                <Index>{String(i + 1).padStart(2, '0')}</Index>
                            </NavItem>
                        </Link>
                        <AnimatePresence exitBeforeEnter>
                            {((i < arr.length - 1 &&
                                (screens.indexOf(screen) === screenIndex ||
                                    (screens.indexOf(screen) < (screenIndex || 0) &&
                                        (screenIndex || 0) <
                                            screens.indexOf(arr[i + 1]?.[0])))) ||
                                (screens.indexOf(screen) + 1 ===
                                    screens.length - 1 &&
                                    screens.indexOf(screen) + 1 ===
                                        screenIndex)) && (
                                <Separator
                                    initial={{height: 0}}
                                    animate={{height: 150}}
                                    exit={{height: 0}}
                                    transition={{
                                        duration: 1,
                                        ease: 'easeInOut',
                                    }}
                                />
                            )}
                        </AnimatePresence>
                    </React.Fragment>
                ))}
        </ScrollNavContainer>
    );
};

export default memo(ScrollNav);
