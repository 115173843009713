import {useIsPresent} from 'framer-motion';

// import TrianglesIcon from '~assets/icons/two-triangles.svg';
// import Countdown from '/~components/atoms/Countdown';
import Orbits, {OrbitsProps} from '~components/atoms/Orbits';
import RWDHelper from '~components/atoms/RWDHelper';
// import {TextInput} from '~components/atoms/TextInput/styles';
import {useScopeTranslation} from '~hooks/useScopeTranslation';
import Astronaut from '~components/atoms/Astronaut';
import MobileScreenScrollDetector from '~components/atoms/MobileScreenScrollDetector';
import {orbitsConfig} from '~components/organisms/Homepage';
import {Routes} from '~config/routes';
// import {useCountdown} from '~components/providers/CountdownProvider';
import Button from '~components/atoms/Button';

import {astronautConfig} from '../../HomepageAstronaut';

import {
    AstronautContainer,
    CountdownCompletedContainer,
    // BottomLeftContainer,
    // CountdownCompletedContainer,
    CountdownContainer,
    // InputContainer,
    LaunchSectionContainer,
    // LeftText,
    RightText,
} from './styles';

const BASE_DELAY = 1;

type LaunchProps = {
    showRightText?: boolean;
    onCompleted?: () => void;
};

const Launch = ({showRightText = true}: LaunchProps) => {
    const {t} = useScopeTranslation('homepage', 'launch');

    const isPresent = useIsPresent();

    // const {date} = useCountdown();

    return (
        <LaunchSectionContainer>
            <MobileScreenScrollDetector screen="launch" />
            {showRightText && (
                <RightText
                    initial={{opacity: 0, y: '100%'}}
                    animate={{
                        opacity: 1,
                        y: '0%',
                    }}
                    exit={{opacity: 0, y: '-100%'}}
                    transition={{
                        delay: isPresent ? BASE_DELAY + 0.5 : 0,
                        ease: 'easeOut',
                        duration: 0.5,
                    }}
                >
                    {t('rightText')}
                </RightText>
            )}

            <CountdownContainer
                initial={{opacity: 0, y: '100%'}}
                animate={{
                    opacity: 1,
                    y: '0%',
                }}
                exit={{opacity: 0, y: '-100%'}}
                transition={{
                    delay: isPresent ? BASE_DELAY : 0,
                    ease: 'easeOut',
                    duration: isPresent ? 0.5 : 0.5,
                }}
            >
                {/* <Countdown
                    label={t('common:countdown.label')}
                    date={date}
                    completed={
                        <CountdownCompletedContainer>
                            <Button
                                {...(onCompleted
                                    ? {onClick: onCompleted}
                                    : {href: Routes.Mint})}
                            >
                                {t('common:menu.buttonText')}
                            </Button>
                        </CountdownCompletedContainer>
                    }
                /> */}
                <CountdownCompletedContainer>
                    <Button href={Routes.Mint}>{t('common:menu.buttonText')}</Button>
                </CountdownCompletedContainer>
            </CountdownContainer>
            <RWDHelper
                mobile={
                    <AstronautContainer>
                        <Orbits {...(orbitsConfig['launch'] as OrbitsProps)} />
                        <Astronaut {...astronautConfig['launch']} />
                    </AstronautContainer>
                }
            />
            {/*<BottomLeftContainer*/}
            {/*    initial={{opacity: 0, y: '100%'}}*/}
            {/*    animate={{*/}
            {/*        opacity: 1,*/}
            {/*        y: '0%',*/}
            {/*    }}*/}
            {/*    exit={{opacity: 0, y: '-100%'}}*/}
            {/*    transition={{*/}
            {/*        delay: isPresent ? BASE_DELAY + 0.5 : 0,*/}
            {/*        ease: 'easeOut',*/}
            {/*        duration: 0.5,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <TrianglesIcon />*/}
            {/*    <LeftText>{t('leftText')}</LeftText>*/}
            {/*    <InputContainer>*/}
            {/*        <TextInput placeholder={t('notificationPlaceholder')} />*/}
            {/*        <Button>{t('notificationSignupButton')}</Button>*/}
            {/*    </InputContainer>*/}
            {/*</BottomLeftContainer>*/}
        </LaunchSectionContainer>
    );
};

export default Launch;
