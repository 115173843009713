import styled from 'styled-components';

import Motion from '~components/atoms/Motion';
import {Paragraph} from '~components/atoms/Text/styles';
import {above, below} from '~config/breakpoints';

export const SuperpowersContainer = styled(Motion.section)`
    z-index: 1;

    ${above.laptop} {
        padding-left: 40px !important;
        position: relative;
    }
`;

export const Title = styled(Motion.h3)`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    ${above.laptop} {
        margin: 10vh 0 21px;
        max-width: 240px;
    }
`;

export const Text = styled(Paragraph)`
    ${above.laptop} {
        max-width: 240px;
    }

    ${below.laptop} {
        margin: 21px 0;
    }
`;

export const MobileBubbleContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 920px;
    left: calc(-1 * var(--h-padding));
    overflow: hidden;
`;
